import React, { useState } from "react";
import { Eye, EyeOff } from "react-feather";
import Typography from "../typography/Typography";
import { getTimeAgo } from "../../utils/dates/TimeAgoUtil";
import { Tooltip } from "antd";
import { NotificationsService } from "../../services/notification/Notifications";
import { RootState } from "../../state/store";
import { useSelector, useDispatch } from "react-redux";
import Cta from "../buttons/Cta";
import { navigate } from "gatsby";
import { Capitalize } from "../../utils/text/Capitalize";
import { useTranslation } from "react-i18next";
import {
  hideNotifications,
  setNotificationCount,
  updateNotificationCount,
} from "../../state/notification/NotificationSlice";
import { setRootAside } from "../../state/component/AsideSlice";
import LoadingSpinner from "../buttons/LoadingSpinner";

interface Notification {
  id: number;
  title: string;
  message: string;
  createdAt: string;
  is_read: boolean;
  action?: string;
  action_value?: string;
}

interface NotificationsProps {
  notifications: Notification[];
  onNotificationUpdate: (updatedNotification: Notification) => void;
}

const NotificationList: React.FC<NotificationsProps> = ({
  notifications,
  onNotificationUpdate,
}) => {
  const [loadingState, setLoadingState] = useState<number | null>(null);
  const notificationService = new NotificationsService();
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const updateNotificationStatus = async (
    notificationId: number,
    is_read: boolean
  ) => {
    setLoadingState(notificationId);
    try {
      await notificationService.updateNotification(
        notificationId,
        companyId,
        is_read
      );
      const updatedNotification = notifications.find(
        (n) => n.id === notificationId
      );
      if (updatedNotification) {
        const updated = { ...updatedNotification, is_read };
        onNotificationUpdate(updated);
        dispatch(updateNotificationCount(is_read ? -1 : 1));
      }
    } catch (error) {
      console.error("Failed to update notification:", error);
    } finally {
      setLoadingState(null);
    }
  };

  const handleActionClick = (notification: Notification) => {
    dispatch(hideNotifications());
    if (!notification.is_read) {
      updateNotificationStatus(notification.id, true);
    }
    if (notification.action === "NAVIGATE") {
      navigate(notification.action_value!);
    }
    if (notification.action === "ASIDE") {
      if (notification.title === "Added to ticket") {
        dispatch(
          setRootAside({
            component: "ticket",
            data: { ticket: { id: notification.action_value } },
            title: "Ticket",
          })
        );
      }
    }
  };

  return (
    <div
      className="h-screen md:h-80 overflow-y-scroll"
      style={{
        scrollbarWidth: "thin",
      }}
    >
      {notifications.length > 0 ? (
        notifications.map((notification) => (
          <div
            key={notification.id}
            className="p-4 border-b border-gray-200 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-slate-700 relative"
          >
            <div
              className={`absolute visible z-10 ${
                !notification.is_read
                  ? "text-blue-700 hover:text-blue-500"
                  : "text-red-500 hover:text-red-700"
              } top-3 right-3 h-2 w-2 rounded-full -translate-x-[120%]`}
            >
              {loadingState === notification.id ? (
                <LoadingSpinner />
              ) : !notification.is_read ? (
                <Tooltip title="Mark as read" placement="rightBottom">
                  <button
                    onClick={() =>
                      updateNotificationStatus(notification.id, true)
                    }
                  >
                    <Eye size={16} />
                  </button>
                </Tooltip>
              ) : (
                <Tooltip title="Mark as unread" placement="rightBottom">
                  <button
                    onClick={() =>
                      updateNotificationStatus(notification.id, false)
                    }
                  >
                    <EyeOff size={16} />
                  </button>
                </Tooltip>
              )}
            </div>

            <div className="flex items-center gap-1">
              <Typography tag={"p"} type={"semibold"}>
                {notification.title}
              </Typography>
              {!notification.is_read && (
                <div className="bg-red-500 h-2 w-2 rounded-full"></div>
              )}
            </div>
            <Typography tag={"p"} type={"default"}>
              {notification.message}
            </Typography>
            <span className="text-xs text-gray-500">
              {getTimeAgo(notification.created_at, t)}
            </span>
            {notification.action_value && (
              <Cta
                text={Capitalize(t(notification.action))}
                onClick={() => handleActionClick(notification)}
              />
            )}
          </div>
        ))
      ) : (
        <p className="p-4 text-gray-500">No notifications</p>
      )}
    </div>
  );
};

export default NotificationList;
