import React, { FC, useEffect, useState } from "react";
import { Button, DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { LicenseService } from "../../../services/licenses/Licenses";
import Table from "../../tables/Table";
import TableRow from "../../tables/TableRow";
import TableItem from "../../tables/TableItem";
import SelectBox from "../../inputs/selectbox/SelectBox";
import { clearAsides, setRootAside } from "../../../state/component/AsideSlice";
import { ProjectsService } from "../../../services/project/Projects";
import { RootState } from "../../../state/store";
import { setCurrentProject } from "../../../state/project/ProjectSlice";
import Typography from "../../typography/Typography";
import dayjs from "dayjs";

interface License {
  id: string;
  name: string;
}

export interface Props {
  data: {
    task: any;
  }
}

const LicenseOffboarding: FC<Props> = ({ data: { task }}) => {
  const [licenses, setLicenses] = useState<License[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs(new Date()));
  const [selectedActions, setSelectedActions] = useState<{
    [key: string]: { action: string; name: string };
  }>({});
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const licenseService = new LicenseService();
  const projectService = new ProjectsService();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchLicenses();
  }, []);

  const fetchLicenses = async () => {
    setLoading(true);
    try {
      const userLicenses = await licenseService.getUserLicenses(
        companyId,
        task.metadata[0].value
      );
      const initialSelectedActions: {
        [key: string]: { action: string; name: string };
      } = {};
      userLicenses.forEach((license) => {
        initialSelectedActions[license.id] = {
          action: "opzeggen",
          name: license.bedrijf_licentie.licentie.lic_licentie,
        };
      });
      setSelectedActions(initialSelectedActions);
      setLicenses(userLicenses);
    } catch (error) {
      console.error("Error fetching licenses:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectChange = (licenseId: string, action: string) => {
    setSelectedActions((prevState) => ({
      ...prevState,
      [licenseId]: {
        ...prevState[licenseId],
        action: action,
      },
    }));
  };

  const handleSubmit = async () => {
    const today = new Date();
    const opzeggenList = [];
    const behoudenList = [];

    Object.entries(selectedActions).forEach(([id, { action, name }]) => {
      if (action === "opzeggen") {
        opzeggenList.push(name);
      } else if (action === "behouden") {
        behoudenList.push(name);
      }
    });

    const opzeggenText =
      opzeggenList.length > 0 ? `Opzeggen: ${opzeggenList.join(", ")}` : "";
    const behoudenText =
      behoudenList.length > 0 ? `Behouden: ${behoudenList.join(", ")}` : "";

    const assignments = [
      {
        proj_omschrijving: `De volgende acties zijn vereist voor het offboarden: ${opzeggenText} ${behoudenText}`,
        projFaseRegel_type: "cmctaak",
        projFaseRegel_extra: "CMC",
      },
    ];

    dispatch(
     clearAsides()
    );

    try {
      const project = await projectService.addAssignments(
        assignments,
        task.phase_id,
        task.rule_id,
        companyId
      );

      dispatch(setCurrentProject(project.result));
    } catch (error) {
      console.error("Error adding assignments:", error);
    }
  };

  const options = [
    { value: "opzeggen", label: "Opzeggen" },
    { value: "behouden", label: "Behouden" },
  ];

  return (
    <div className="overflow-hidden">
      <div className="col-span-2 border dark:border-slate-800 rounded-lg h-full">
        <Table header={["Licentie", "Acties"]}>
          {licenses.map((lic) => (
            <TableRow key={lic.id}>
              <TableItem type="tiny">
                {lic.bedrijf_licentie.licentie.lic_licentie}
              </TableItem>
              <TableItem type="tiny">
                <SelectBox
                  onChange={(action) =>
                    handleSelectChange(lic.id, action as string)
                  }
                  defaultValue={"opzeggen"}
                  options={options}
                  loading={loading}
                  search={false}
                  props={undefined}
                />
              </TableItem>
            </TableRow>
          ))}
        </Table>
      </div>
      <div className="mt-5 mb-2">
        <div className="mb-1">
          <Typography tag={"h1"} type={"semibold"}>
            Uitvoeringsdatum
          </Typography>
        </div>
        <DatePicker
          format="DD/MM/YY"
          placeholder="Datum"
          value={selectedDate}
          defaultValue={selectedDate}
          style={{ display: "block" }}
          required={true}
          onChange={(date) => setSelectedDate(date)}
        />
      </div>
      <div className="mt-3">
        <Button onClick={handleSubmit}>Keuze opslaan</Button>
      </div>
    </div>
  );
};

export default LicenseOffboarding;
