import React, { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import TextHeader from "../../../components/headers/TextHeader";
import BranchForm from "../../../components/forms/branches/BranchForm";

export interface Props { 
  data: {
    branch: any;
  }
}

const BranchDetailContainer: FC<Props> = ({ data: { branch }}) => {
  return (
    <div>
      <TextHeader title={branch.ves_name} subtitle={"Vestiging"} />
      <BranchForm branch={branch} />
    </div>
  );
};

export default BranchDetailContainer;
