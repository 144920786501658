import React, { FC, useEffect, useState } from "react";
import Table from "../../../components/tables/Table";
import TableItem from "../../../components/tables/TableItem";
import { Tag, Trash2, UserX } from "react-feather";
import { GroupsService } from "../../../services/groups/Groups";
import CurrencyFormatter from "../../../utils/text/CurrencyFormatter";
import Typography from "../../../components/typography/Typography";
import Cta from "../../../components/buttons/Cta";
import { Popconfirm } from "antd";
import AddCouponForm from "../../../components/forms/coupon/AddCouponForm";
import LoadingSpinner from "../../../components/buttons/LoadingSpinner";
import { CouponService } from "../../../services/coupons/CouponService";
import { useDispatch, useSelector } from "react-redux";
import { addToast } from "../../../state/toast/ToastSlice";
import { deleteCoupon, setCoupons } from "../../../state/coupon/CouponSlice";
import { UsersService } from "../../../services/users/Users";
import PopoverForm from "../../../components/popover/PopoverForm";
import { setRootAside } from "../../../state/component/AsideSlice";
import { useTranslation } from "react-i18next";
import { RootState } from "../../../state/store";
import {
  CompanyModules,
  hasModule,
} from "../../../utils/auth/AuthorizationUtility";

type Group = {
  group: any;
};

const GroupMembersContainer: FC<Group> = ({ group }) => {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const service = new GroupsService();
  const couponService = new CouponService();
  const userService = new UsersService();
  const coupons = useSelector((state: any) => state.coupon.coupons);
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const modules = useSelector((state: RootState) => state.auth.modules);
  const dispatch = useDispatch();

  const fetchUsers = async () => {
    setLoading(true);
    const users = await service.getUsersFromGroupById(
      companyId,
      group.bedGr_id
    );
    setUsers(users ? users : []);
    const extractedCoupons = users.map((user: any) => user.coupon);
    dispatch(setCoupons(extractedCoupons));
    setLoading(false);
  };

  const onDelete = async (couponId: number, userId: number) => {
    setConfirmLoading(true);
    await couponService.deleteCoupon(companyId, couponId);

    dispatch(deleteCoupon(userId));

    setConfirmLoading(false);
    dispatch(
      addToast({
        description: "De coupon is verwijderd.",
        position: "bottomRight",
        style: "success",
      })
    );
  };

  const onRemoveUser = async (userId: number) => {
    setConfirmLoading(true);
    await userService.removeFromGroup(companyId, userId);

    setUsers((prevUsers) => prevUsers.filter((user) => user.geb_id !== userId));

    setConfirmLoading(false);
    dispatch(
      addToast({
        description: "De gebruiker is uit de groep verwijderd.",
        position: "bottomRight",
        style: "success",
      })
    );
  };

  const createCouponsGroup = async () => {
    const createdCoupons = await couponService.createCouponsGroup(
      companyId,
      users,
      group.bedGr_bedrag
    );

    dispatch(setCoupons([...coupons, ...createdCoupons]));

    dispatch(
      addToast({
        description: "De coupons zijn aangemaakt.",
        position: "bottomRight",
        style: "success",
      })
    );
  };

  const viewDetail = (user: string[]) => {
    dispatch(
      setRootAside({
        component: "userDetail",
        data: { user: user },
        title: "Gebruiker",
      })
    );
  };

  useEffect(() => {
    if (companyId) {
      fetchUsers();
    }
  }, [group.bedGr_id]);

  const getCouponBedrag = (userId: number, att: string) => {
    const userCoupon = coupons.find(
      (coupon: any) => coupon?.cou_geb_id === userId
    );
    return userCoupon ? userCoupon[att] : 0;
  };

  const tableHeaders: object = [
    "Naam",
    ...(hasModule(modules, CompanyModules.CYOD)
      ? ["Coupon", "Bedrag", "Resterend bedrag"]
      : []),
    "",
  ];

  return (
    <div className="mr-5">
      {loading ? (
        <LoadingSpinner />
      ) : users.length === 0 ? (
        <div className="my-3">
          <Typography tag={"p"} type={"default"}>
            Geen gebruikers in deze groep, ga naar het gebruikers tabblad om
            gebruikers toe te voegen.
          </Typography>
        </div>
      ) : (
        <>
          <Table header={tableHeaders}>
            {users.map((user) => (
              <tr key={user.geb_id}>
                <TableItem>
                  <div
                    className="cursor-pointer"
                    onClick={() => viewDetail(user)}
                  >
                    <div>
                      {user.geb_voornaam} {user.geb_naam}
                    </div>
                    <Typography tag={"p"} type={"label"}>
                      {user.email}
                    </Typography>
                  </div>
                </TableItem>
                {hasModule(modules, CompanyModules.CYOD) && (
                  <>
                    <TableItem>
                      {getCouponBedrag(user.geb_id, "cou_id") !== 0 ? (
                        <Popconfirm
                          title={t("Verwijder coupon")}
                          description={t("Wilt u deze coupon verwijderen?")}
                          onConfirm={() =>
                            onDelete(
                              getCouponBedrag(user.geb_id, "cou_id"),
                              user.geb_id
                            )
                          }
                          okText={t("Verwijder")}
                          cancelText={t("Annuleer")}
                          okButtonProps={{ loading: confirmLoading }}
                          okType="danger"
                          icon={null}
                        >
                          <button className="text-red-700 hover:text-red-800">
                            <Trash2 size={18} />
                          </button>
                        </Popconfirm>
                      ) : (
                        <PopoverForm
                          form={<AddCouponForm user={user}></AddCouponForm>}
                          button={
                            <button className="hover:text-blue-700">
                              <Tag size={18} />
                            </button>
                          }
                          title="Coupon toevoegen"
                        />
                      )}
                    </TableItem>
                    <TableItem>
                      <CurrencyFormatter
                        tag="excl. BTW"
                        price={getCouponBedrag(
                          user.geb_id,
                          "cou_initial_value"
                        )}
                      />
                    </TableItem>
                    <TableItem>
                      <CurrencyFormatter
                        tag="excl. BTW"
                        price={getCouponBedrag(user.geb_id, "cou_bedrag")}
                      />
                    </TableItem>
                  </>
                )}
                <TableItem>
                  <Popconfirm
                    title={t("Verwijder gebruiker")}
                    description={t(
                      "Wilt u deze gebruiker uit de groep verwijderen?"
                    )}
                    onConfirm={() => onRemoveUser(user.geb_id)}
                    okText={t("Verwijder")}
                    cancelText={t("Annuleer")}
                    okType="danger"
                    icon={null}
                  >
                    <button className="hover:text-red-800">
                      <UserX size={18} />
                    </button>
                  </Popconfirm>
                </TableItem>
              </tr>
            ))}
          </Table>
          {hasModule(modules, CompanyModules.CYOD) && (
            <div className="my-3 flex gap-4">
              <Cta
                text={"Alle coupons aanmaken"}
                onClick={createCouponsGroup}
              ></Cta>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default GroupMembersContainer;
