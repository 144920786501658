import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToast } from "../../state/toast/ToastSlice";
import { LicenseService } from "../../services/licenses/Licenses";
import { TruncateString } from "../../utils/text/TruncateString";
import SearchTable from "../../components/inputs/search/SearchTable";
import Table from "../../components/tables/Table";
import TableItem from "../../components/tables/TableItem";
import FormButton from "../../components/buttons/FormButton";
import TextHeader from "../../components/headers/TextHeader";
import Typography from "../../components/typography/Typography";
import { t } from "i18next";
import { Minus, Plus } from "react-feather";
import { RootState } from "../../state/store";
import { clearAside, clearAsides, setRootAside } from "../../state/component/AsideSlice";
import {
  addLicenseRequest,
  setLicenseRequests,
} from "../../state/license/LicenseRequestSlice";

const CreateLicenseRequest: FC = () => {
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const profile = useSelector((state: RootState) => state.profile);
  const auth = useSelector((state: RootState) => state.auth);
  const [licenses, setLicenses] = useState<any[]>([]);
  const [filteredLicenses, setFilteredLicenses] = useState<any[]>([]);
  const [search, setSearch] = useState("");
  const [reason, setReason] = useState("");
  const [selectedLicense, setSelectedLicense] = useState<string | null>(null);

  const dispatch = useDispatch();
  const licenseService = new LicenseService();

  useEffect(() => {
    const fetchLicenses = async () => {
      try {
        const fetchedLicenses = await licenseService.getLicenses(companyId);
        setLicenses(fetchedLicenses);
        setFilteredLicenses(fetchedLicenses);
      } catch (error) {
        dispatch(
          addToast({
            description: "Kon licenties niet laden. Probeer het opnieuw.",
            position: "bottomRight",
            style: "error",
          })
        );
      }
    };

    fetchLicenses();
  }, [dispatch]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    filterLicenses(event.target.value);
  };

  const filterLicenses = (searchValue: string) => {
    const filtered = licenses.filter((license: any) => {
      return (
        license.lic_licentie
          .toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        license.lic_type.toLowerCase().includes(searchValue.toLowerCase())
      );
    });
    setFilteredLicenses(filtered);
  };

  const handleLicenseSelect = (id: string) => {
    setSelectedLicense(id);
  };

  const handleRemoveLicense = () => {
    setSelectedLicense(null);
  };

  const handleSubmit = async () => {
    if (!selectedLicense) {
      dispatch(
        addToast({
          description: "Selecteer een licentie.",
          position: "bottomRight",
          style: "error",
        })
      );
      return;
    }

    try {
      const selectedLicenseData = licenses.find(
        (license: any) => license.id_licentie === selectedLicense
      );

      const licenseRequest = {
        user_id: Number(profile.userId),
        company_id: Number(auth.roles[0].companyId),
        license_id: parseInt(selectedLicense, 10),
        status: "pending",
        reason: reason,
      };

      await licenseService.createUserRequestedLicense(licenseRequest);
      dispatch(
        addLicenseRequest({
          ...licenseRequest,
          lic_licentie: selectedLicenseData.lic_licentie,
        })
      );
      dispatch(
        addToast({
          description: "Licentieaanvraag succesvol ingediend.",
          position: "bottomRight",
          style: "success",
        })
      );
      dispatch(clearAsides());
      setSelectedLicense(null);
      setReason("");
    } catch (error) {
      dispatch(
        addToast({
          description:
            "Mislukt om licentieaanvraag in te dienen. Probeer het opnieuw.",
          position: "bottomRight",
          style: "error",
        })
      );
    }
  };

  const tableHeaders: string[] = ["Licentie", "Categorie", ""];

  return (
    <div>
      <TextHeader title={"Licentie aanvragen"} />
      <Typography tag={"p"} type={"default"}>
        Hier kunt u een licentie aanvragen bij de beheerders van uw bedrijf. Als
        uw aanvraag wordt goedgekeurd, zal de licentie worden toegevoegd.
      </Typography>
      <div className="mt-3">
        <SearchTable
          handleChange={handleSearchChange}
          placeholder="Licentie zoeken..."
          value={search}
        />
        <Table header={tableHeaders}>
          {filteredLicenses.map((lic) => {
            const license = lic;
            const isSelected = selectedLicense === license.id_licentie;

            return (
              <tr key={license.id}>
                <TableItem type="tiny">
                  <TruncateString text={license.lic_licentie} limit={35} />
                </TableItem>
                <TableItem type="tiny">{license.lic_type}</TableItem>
                <TableItem type="tiny-np">
                  <div>
                    {isSelected ? (
                      <button
                        className="text-red-500 hover:text-red-700 text-lg flex items-center"
                        onClick={handleRemoveLicense}
                      >
                        <Minus size={18} />
                      </button>
                    ) : (
                      selectedLicense === null && (
                        <button
                          className="text-green-500 hover:text-green-700 text-lg flex items-center"
                          onClick={() =>
                            handleLicenseSelect(license.id_licentie)
                          }
                        >
                          <Plus size={18} />
                        </button>
                      )
                    )}
                  </div>
                </TableItem>
              </tr>
            );
          })}
        </Table>
      </div>

      <div className="my-5">
        <Typography tag={"p"} type={"semibold"}>
          Reden voor aanvraag
        </Typography>
        <textarea
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          maxLength={255}
          placeholder={t("Geef reden op (max 255 tekens)")}
          className="border border-gray-300 dark:border-slate-600 dark:text-gray-300 text-sm rounded-lg block w-full px-2.5 py-1.5 mt-1 bg-transparent dark:bg-gray-900 focus:outline-blue-700"
        />
      </div>

      <FormButton
        onClick={handleSubmit}
        disabled={selectedLicense === null}
        text="Verzoek indienen"
      />
    </div>
  );
};

export default CreateLicenseRequest;
