import React, { FC } from "react";
import { AsideInterface } from "../../state/component/AsideSlice";

//usable components
import TicketDetailContainer from "../../containers/tickets/TicketDetailContainer";
import SimDetailContainer from "../../containers/telecom/sim/SimDetailContainer";
import LoadingSpinner from "../buttons/LoadingSpinner";
import UserDetailContainer from "../../containers/settings/users/UserDetailContainer";
import ImportUsersForm from "../forms/users/ImportUsers";
import FiltersSidebar from "../products/FiltersSidebar";
import ShoppingCartAside from "../../containers/shop/cart/ShoppingCartAside";
import GroupDetailContainer from "../../containers/settings/groups/GroupDetailContainer";
import GroupCreateContainer from "../../containers/settings/groups/GroupCreateContainer";
import UserCreateContainer from "../../containers/settings/users/UserCreateContainer";
import BranchDetailContainer from "../../containers/settings/branches/BranchDetailContainer";
import BranchCreateContainer from "../../containers/settings/branches/BranchCreateContainer";
import OrderDetailContainer from "../../containers/orders/OrderDetailContainer";
import AssetDetailContainer from "../../containers/hardware/HardwareDetailContainer";
import AddHardware from "../../containers/hardware/AddHardware";
import ProjectDetailContainer from "../../containers/projects/ProjectDetailContainer";
import SimOnboarding from "../../containers/projects/SimOnboarding";
import HardwareOnboarding from "../forms/users/HardwareOnboarding";
import LicenseOnboarding from "../forms/users/LicenseOnboarding";
import LicenseOffboarding from "../forms/users/LicenseOffboarding";
import SimOffboarding from "../forms/users/SimOffboarding";
import HardwareOffboarding from "../forms/users/HardwareOffboarding";
import RequestNewSimcards from "../../containers/telecom/sim/RequestNewSimcards";
import Crumbs from "../navigation/Crumbs";
import CmcTask from "../forms/project/CmcTask";
import CompanyLicenseDetail from "../../containers/licenses/CompanyLicenseDetail";
import AddLicense from "../../containers/licenses/AddLicense";
import CreateTicketContainer from "../../containers/tickets/CreateTicketContainer";
import AddProjectUser from "../../containers/projects/AddProjectUser";
import CreateLicenseRequest from "../../containers/licenses/CreateLicenseRequest";
import ActivateSimRequest from "../../containers/telecom/sim/ActivateSimRequest";
import LicenseRequestDetail from "../../containers/licenses/LicenseRequestDetail";

const components: Record<string, React.FC<any>> = {
  default: LoadingSpinner,
  ticket: TicketDetailContainer,
  ticketCreate: CreateTicketContainer,
  simDetail: SimDetailContainer,
  projectUsers: AddProjectUser,
  userDetail: UserDetailContainer,
  importUsers: ImportUsersForm,
  userCreate: UserCreateContainer,
  groupDetail: GroupDetailContainer,
  groupCreate: GroupCreateContainer,
  branchDetail: BranchDetailContainer,
  branchCreate: BranchCreateContainer,
  orderDetail: OrderDetailContainer,
  assetDetail: AssetDetailContainer,
  addHardware: AddHardware,
  addLicense: AddLicense,
  requestLicense: CreateLicenseRequest,
  licenseDetail: CompanyLicenseDetail,
  licenseRequestDetail: LicenseRequestDetail,
  projectDetail: ProjectDetailContainer,
  telecomOnboard: SimOnboarding,
  hardwareOnboard: HardwareOnboarding,
  licenseOnboard: LicenseOnboarding,
  telecomOffboarding: SimOffboarding,
  hardwareOffboarding: HardwareOffboarding,
  licenseOffboarding: LicenseOffboarding,
  cmcTask: CmcTask,
  filtersSidebar: FiltersSidebar,
  shoppingCart: ShoppingCartAside,
  requestNewSimcards: RequestNewSimcards,
  ActivateSimRequest: ActivateSimRequest,
};

const Aside: FC<AsideInterface> = ({ component, data, callback }) => {

  const Component = components[component];

  return (
    <>
      {data ? (
        <Component data={data} callback={callback} />
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};

export default Aside;
