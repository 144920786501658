import { FC } from "react";
import TextHeader from "../../../components/headers/TextHeader";
import React from "react";
import EditUserForm from "../../../components/forms/users/EditUserForm";

export interface AsideProps {
  data: {
    title: string;
  },
  callback?: (e: any) => void;
}

const UserCreateContainer: FC<AsideProps> = ({ data: { title }, callback }) => {
  console.log("UserCreateContainer", callback);
  return (
    <div>
      <TextHeader title="Aanmaken" subtitle="Gebruiker" />
      <EditUserForm user={{}} title={title} callback={callback}></EditUserForm>
    </div>
  );
};

export default UserCreateContainer;
