import React, { FC, useState } from "react";
import DateInput from "../../inputs/dates/DateInput";
import dayjs from "dayjs";
import Form from "../Form";
import { useDispatch, useSelector } from "react-redux";
import { TicketService } from "../../../services/tickets/Tickets";
import { updateAside } from "../../../state/component/AsideSlice";
import { setSimcard } from "../../../state/simdata/SimcardsSlice";
import Typography from "../../typography/Typography";
import { useTranslation } from "react-i18next";
import { RootState } from "../../../state/store";
import { SimcardInterface } from "../../../interfaces/Simcard";
import { addToast } from "../../../state/toast/ToastSlice";

export interface Props {
  data: {
    sim: SimcardInterface;
  }
}

const DisableSimcard: FC<Props> = ({ data: { sim } }) => {
  const { t } = useTranslation();
  const [date, setDate] = useState(dayjs());
  const [loading, setLoading] = useState(false);
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const ticketService = new TicketService();
  const dispatch = useDispatch();

  const handleChange = (date) => {
    setDate(date);
  };

  const handleSubmit = async (e: Event) => {
    e.preventDefault();
    setLoading(true);

    const prop = {
      sim_status: t("Opzeggen"),
    };
    dispatch(updateAside({ props: prop, object: "sim" }));
    dispatch(setSimcard({ props: prop, key: sim.key }));

    await ticketService.createTicketZendesk(companyId, {
      subject: `Nummer opzeggen ${sim.sim_unique_nr}`,
      description: `Nummer opzeggen | Simkaart#: ${sim.sim_unique_nr} | GSM-nummer: ${sim.sim_gsm_nr} vanaf ${dayjs(date).format('DD/MM/YYYY')}`,
      department: "Telecom",
      linkedResource: {
        id: sim.sim_unique_nr,
        type: "TELECOM",
      },
      // meta: sim.sim,
    });

    setLoading(false);

    dispatch(
      addToast({
        description: t("Uw aanvraag voor het opzeggen van het gsm nummer is ingediend en wordt verwerkt. U kunt dit opvolgen via het bijhorende ticket."),
        position: "bottomRight",
        style: "success",
      })
    );
  };

  return (
    <Form
      buttonLabel="Opzeggen"
      handleSubmit={handleSubmit}
      gridSize="grid-cols-2"
      loading={loading}
      buttonStyle="danger"
    >
      <div className="block mt-2 mb-2">
        <Typography tag="label" type="label">
          informatie opzeggen nummer
        </Typography>
        <div className="mb-2"></div>
        <Typography tag="label" type="label">
          Datum
        </Typography>
        <div className="block">
          <DateInput onChange={handleChange} value={date} />
        </div>
      </div>
      <div className="mb-3"></div>
    </Form>
  );
};
export default DisableSimcard;
