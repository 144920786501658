import React, { FC } from "react";
import Typography from "../typography/Typography";
import { useTranslation } from "react-i18next";

type Props = {
  label: number;
};

const StatusTag: FC<Props> = ({ label }) => {
  const { t } = useTranslation();

  const renderStyle = (style: number) => {
    switch (style) {
      case 1:
        return "bg-green-100 text-green-700 rounded-full";
      case 0:
        return "bg-red-100 text-red-700 rounded-full";
      default:
        return "bg-orange-50 text-orange-400 rounded-full";
    }
  };

  const renderText = (status: number) => {
    switch (status) {
      case 1:
        return "Actief";
      case 2:
        return "Puk-code aanvragen";
      case 3:
        return "Ruil Simkaart";
      case 4:
        return "Tariefplan aanvragen";
      case 5:
        return "Wordt verwerkt";
      case 6:
        return "Tijdelijk buiten dienst";
      case 7:
        return "Omschakelen Pay&Go";
      default:
        return "Activeren";
    }
  };

  return (
    <span
      className={`${renderStyle(label)} text-xs font-bold uppercase px-3 py-1`}
    >
      {t(renderText(label))}
    </span>
  );
};

export default StatusTag;
