import React, { FC, useEffect, useState } from "react";
import { Switch } from "antd";
import { RootState } from "../../../state/store";
import { useDispatch, useSelector } from "react-redux";
import { updateAside } from "../../../state/component/AsideSlice";
import { setSimcard } from "../../../state/simdata/SimcardsSlice";
import Typography from "../../typography/Typography";
import { TarifService } from "../../../services/simcards/Tarifs";
import { TicketService } from "../../../services/tickets/Tickets";
import { addToast } from "../../../state/toast/ToastSlice";
import { SimcardInterface } from "../../../interfaces/Simcard";

export interface Props {
  data: { 
    sim: SimcardInterface;
    title: string;
  }
}

const Mpay: FC<Props> = ({ data: { sim, title }}) => {
  const [loading, setLoading] = useState(false);
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const [features, setFeatures] = useState({});
  const service = new TarifService();
  const ticket = new TicketService();

  const dispatch = useDispatch();

  const onChange = async (event, checked) => {
    setLoading(true);

    setFeatures((prev) => ({
      ...prev,
      [event]: checked,
    }));

    const find = findFeature(event);
    let subject = "";

    if (find && find.prx_sta_id) {
      await service.removeService(find.prx_sta_id, companyId);
      subject = `${event} uitschakelen`;

      const updatedFeatures = [...sim.sim_features];
      const filteredFeatures = updatedFeatures.filter(
        (feature) => feature.prx_sta_id !== find.prx_sta_id
      );
    } else {
      await service.addService(
        {
          prx_sta_sim_id: sim.sim_id,
          prx_sta_option: event,
        },
        companyId
      );
      subject = `${event} activeren`;
    }

    ticket.createTicketZendesk(companyId, {
      subject: subject,
      description: `${subject} voor simkaart ${sim.sim_unique_nr}`,
      department: "Telecom",
      linkedResource: {
        id: sim.sim_unique_nr,
        type: "TELECOM",
      },
    });

    dispatch(
      addToast({
        description: [
          `Event ${checked ? "inschakelen" : "uitschakelen"}`,
          { event: event },
        ],
        position: "bottomRight",
        style: "success",
      })
    );

    setLoading(false);
  };

  const findFeature = (item: string) => {
    return sim?.sim_features?.find(
      ({ prx_sta_option }) => prx_sta_option === item
    );
  };

  useEffect(() => {
    const updatedFeatures = {
      voicemail: false,
      "m-pay": false,
    };
    sim?.sim_features?.forEach((feature) => {
      updatedFeatures[feature.prx_sta_option] = true;
    });

    setFeatures(updatedFeatures);
  }, [sim]);

  return (
    <div className="flex space-x-6">
      {Object.keys(features).map((item, i) => (
        <div key={item}>
          <Typography tag="span" type="bold">
            {item}
          </Typography>
          <div className="flex space-x-3 mb-5">
            <Typography tag="span" type="label">
              {features[item] ? "Ingeschakeld" : "Uitgeschakeld"}
            </Typography>
            <Switch
              checked={features[item]}
              onChange={(checked) => onChange(item, checked)}
              loading={loading}
              className={features[item] ? "" : "bg-slate-200 dark:bg-slate-700"}
              id={item}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Mpay;
