import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, DatePicker } from "antd";
import { RootState } from "../../../state/store";
import { ProjectsService } from "../../../services/project/Projects";
import TextHeader from "../../headers/TextHeader";
import LoadingSpinner from "../../buttons/LoadingSpinner";
import Table from "../../tables/Table";
import TableRow from "../../tables/TableRow";
import TableItem from "../../tables/TableItem";
import { UsersService } from "../../../services/users/Users";
import { clearAsides } from "../../../state/component/AsideSlice";
import { setCurrentProject } from "../../../state/project/ProjectSlice";
import { Edit } from "react-feather";
import Typography from "../../typography/Typography";

interface Sim {
  id: string;
  sim_unique_nr: string;
}

interface Props {
  data: any;
}

const CmcTask: FC<Props> = ({ data: { task } }) => {
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );

  const projectService = new ProjectsService();

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    dispatch(
      clearAsides()
    );

    try {
      const project = await projectService.finishAssignment(
        task.phase_id,
        task.rule_id,
        companyId
      );

      dispatch(setCurrentProject(project.result));
    } catch (error) {
      console.error("Error adding assignments:", error);
    }
  };

  return (
    <div className="overflow-hidden">
      <TextHeader title={task.extra} />
      <Typography tag={"p"} type={"default"}>
        {task.description}
      </Typography>
      <div className="mt-3">
        <Button onClick={handleSubmit}>Taak afronden</Button>
      </div>
    </div>
  );
};

export default CmcTask;
