import React, { FC } from "react";
import Tabs from "../../../components/tabs/Tabs";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import TextHeader from "../../../components/headers/TextHeader";
import GroupMembersContainer from "./GroupMembersContainer";
import EditGroupForm from "../../../components/forms/groups/EditGroupForm";

export interface Props {
  data: {
    group: any;
  }
}

const GroupDetailContainer: FC<Props> = ({ data: { group }}) => {
  const tabs = [
    {
      nav: "Bewerken",
      component: [<EditGroupForm group={group} />],
    },
    {
      nav: "Gebruikers",
      component: [<GroupMembersContainer group={group} />],
    },
  ];
  return (
    <div>
      <TextHeader title={group.bedGr_naam} subtitle={"Groep"} />
      <Tabs tabs={tabs} />
    </div>
  );
};

export default GroupDetailContainer;
