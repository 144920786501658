import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Typography from "../../typography/Typography";
import { RootState } from "../../../state/store";
import TextHeader from "../../headers/TextHeader";
import { Form, Button, Radio, UploadFile, Space } from "antd";
import FileUpload from "../../inputs/fileupload/FileUpload";
import BranchSelect from "../../branches/BranchSelect";
import SelectSimcard from "../../../organisms/simcards/SelectSimcard";
import SelectTarif from "../../../organisms/simcards/SelectTarif";
import InputField from "../../inputs/text/InputField";
import SelectBox from "../../inputs/selectbox/SelectBox";
import ToggleSwitch from "../../inputs/toggle/ToggleSwitch";
import dayjs from "dayjs";
import DateInput from "../../inputs/dates/DateInput";
import { t } from "i18next";
import LoadingSpinner from "../../buttons/LoadingSpinner";
import FormButton from "../../buttons/FormButton";
import {
  CompanyModules,
  hasModule,
} from "../../../utils/auth/AuthorizationUtility";
import SelectUser from "../../../organisms/users/SelectUser";
import UserSelect from "../../users/UserSelect";
import Group from "antd/es/input/Group";
import { useTranslation } from "react-i18next";
import { stackAside } from "../../../state/component/AsideSlice";

interface props {
  onSubmit: any;
  loading: boolean;
}

interface FormValuesInterface {
  transferType?: string;
  operator?: string;
  subscriptionType?: string;
  isBudgetManager?: boolean;
  isESim?: boolean;
  date: Date;
}

interface FormSubmitValuesInterface {
  sim: string;
  tarif: any;
  date: any;
  isESim: any;
  isBudgetManager: any;
  user_id: string;
  transferType: string;
  gsmNumber: string;
  operator: string;
  subscriptionType: string;
  branch: any;
}

interface FileTypeInterface { base64String: string, name: string }

const SimOnboardingForm: FC<props> = ({ onSubmit, loading }) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [formValues, setFormValues] = useState<FormValuesInterface>({});
  const dispatch = useDispatch();
  const [createdUser, setCreatedUser] = useState(undefined);

  const { t } = useTranslation();

  const project = useSelector<any, any>(
    (state: RootState) => state.project.currentProject
  );
  const branchState = useSelector((state: RootState) => state.branch);
  const modules = useSelector((state: RootState) => state.auth.modules);

  useEffect(() => {
    if (formValues.transferType === "Nieuwe nummer") {
      form.setFieldsValue({
        operator: undefined,
        subscriptionType: undefined,
      });
      setFormValues((prevValues) => ({
        ...prevValues,
        operator: undefined,
        subscriptionType: undefined,
      }));
    }
  }, [formValues.transferType, form]);

  const handleValuesChange = (_: any, allValues: any) => {
    setFormValues(allValues);
  };

  const createUser = () => {
    dispatch(
      stackAside({
        data: {},
        component: "userCreate",
        title: "Create User",
        callback: (value) => {
          setCreatedUser(value.userId);
          form.setFieldValue("user_id", value.userId);
        }
      })
    );
  };

  const handleFileChange = (updatedFileList: UploadFile[]) => {
    setFileList(updatedFileList);
  };

  const providerOptions = [
    { value: "Proximus", label: "Proximus" },
    { value: "Orange", label: "Orange" },
    { value: "Base", label: "Base" },
    { value: "Telenet", label: "Telenet" },
    { value: "Mobile Vikings", label: "Mobile Vikings" },
    { value: "Andere", label: "Andere" },
  ];

  const handleBranchSelect = (value: number) => {
    const selectedBranch = branchState.branches.find(
      (branch) => branch.ves_id === value
    );
    form.setFieldsValue({ branch: selectedBranch });
  };

  const fileToBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>
        resolve({ base64String: (reader.result as string)?.split(",")[1], name: file.name });

      reader.onerror = (error) => reject(error);
    });

  const handleSubmit = async (values: FormSubmitValuesInterface) => {
    const formData = new FormData();

    console.log(values);

    const gebruikerId = project ? project.metadata[0].value : values ? values.user_id : "";

    const base64Promises = fileList.map((file) => fileToBase64(file as unknown as File));
    const base64Strings = await Promise.all(base64Promises) as [FileTypeInterface];

    base64Strings.forEach(({ base64String, name }) => {
      formData.append("files[]", base64String);
      formData.append("fileNames[]", name);
    });

    formData.append("branch", JSON.stringify(values.branch));

    formData.append(`simcards[sim]`, values.sim);
    formData.append(`simcards[tarif]`, values.tarif.searchLabel);
    formData.append(`simcards[tarifId]`, values.tarif.value);
    console.log(values);
    formData.append("date", values.date.format("DD-MM-YYYY"));
    formData.append("isESim", values.isESim ? "true" : "false");
    formData.append("isBudgetManager", values.isBudgetManager ? "true" : "false");
    formData.append("user_id", gebruikerId);
    formData.append("transferType", values.transferType);

    if (values.transferType === "Overdracht nummer") {
      formData.append("gsm_number", values.gsmNumber);
      formData.append("operator", values.operator);
      formData.append("subscription_type", values.subscriptionType);
    }

    onSubmit(formData);
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      initialValues={{
        esim: false,
        budgetmanager: false,
        date: dayjs(new Date()),
        transferType: "Nieuwe nummer",
        subscriptionType: "Herlaadkaart",
      }}
      onValuesChange={handleValuesChange}
      layout="vertical"
      className={loading ? "animate-pulse" : ""}
    >
      <TextHeader
        title="Simkaarten"
        subtitle={"Nieuwe nummer of nummer overdragen"}
      />
      <Form.Item label="Gebruiker" name="user_id"
        rules={[
          {
            required: true,
            message: "Selecteer een Gebruiker.",
          },
        ]}>
        <Space.Compact style={{ width: "100%" }}>
          <UserSelect key={createdUser}
            handleSelect={(value) => {
              form.setFieldsValue({ user_id: value });
            }}
            defaultValue={createdUser}
          />
          <Button onClick={() => createUser() }>
            {t("+ Gebruiker")}</Button>
        </Space.Compact>
      </Form.Item>
      <Form.Item
        label="Type overdracht"
        name="transferType"
        // initialValue="Nieuwe nummer"
        className="mt-5"
      >
        <Radio.Group
          onChange={(event) => {
            form.setFieldsValue({ transferType: event.target.value });
          }}
        >
          <Radio value={"Nieuwe nummer"}>{t("Nieuwe nummer")}</Radio>
          <Radio value={"Overdracht nummer"}>{t("Overdracht nummer")}</Radio>
        </Radio.Group>
      </Form.Item>
      {formValues.transferType === "Overdracht nummer" ? (
        <>
          <div className="mb-1">
            <Typography tag={"h1"} type={"semibold"}>
              Voor we beginnen
            </Typography>
            <Typography tag={"p"} type={"label"}>
              Voor de activatie zijn enkele documenten nodig:
            </Typography>
            <ul className="mt-2">
              <li className="list-disc ml-5">
                <Typography tag={"p"} type={"default"}>
                  Download alvast de LOA als het nummer wordt overgedragen van
                  een andere operator en neem een kopie van de identiteitskaart
                  van de huidige titularis.
                </Typography>
              </li>
              {formValues.operator === "Proximus" &&
                formValues.subscriptionType === "Abonnement" ? (
                <li className="list-disc ml-5">
                  <Typography tag={"p"} type={"default"}>
                    Download het overdrachtsformulier van Proximus voor een
                    overdracht binnen Proximus.
                  </Typography>
                </li>
              ) : (
                ""
              )}
            </ul>
            <div className="mt-3 mb-5 flex gap-2">
              <Button
                href="../../../LOA_mobile_NL.pdf"
                download="LOA_mobile_NL.pdf"
              >
                Download Letter Of Authorization
              </Button>
              {formValues.operator === "Proximus" &&
                formValues.subscriptionType === "Abonnement" ? (
                <Button
                  href="../../../Overdrachtsdocument_Proximus_NL.pdf"
                  download="Overdrachtsdocument_Proximus_NL.pdf"
                >
                  Download overdrachtsformulier Proximus
                </Button>
              ) : (
                ""
              )}
            </div>
          </div>
          <Form.Item
            label="Huidige GSM-nummer"
            name="gsmNumber"
            rules={[
              {
                required:
                  formValues.transferType === "Overdracht nummer" ? true : false,
                message: "Voer een geldig GSM-nummer in.",
              },
            ]}
          >
            <InputField type="text" name="gsmNumber" placeholder="GSM-nummer" />
          </Form.Item>
          <Form.Item
            label="Huidige operator"
            name="operator"
            // initialValue="Proximus"
            rules={[
              {
                required:
                  formValues.transferType === "Overdracht nummer" ? true : false,
                message: "Selecteer een operator.",
              },
            ]}
          >
            <SelectBox
              search={true}
              options={providerOptions}
              onChange={(event, option) =>
                form.setFieldsValue({ operator: option.value })
              }
            />
          </Form.Item>
          <Form.Item
            label="Maak je gebruik van een herlaadkaart of een abonnement?"
            name="subscriptionType"
          // initialValue="Herlaadkaart"
          >
            <Radio.Group
              onChange={(event) =>
                form.setFieldsValue({ subscriptionType: event.target.value })
              }
            >
              <Radio value={"Herlaadkaart"}>{t("Herlaadkaart")}</Radio>
              <Radio value={"Abonnement"}>{t("Abonnement")}</Radio>
            </Radio.Group>
          </Form.Item>
        </>
      ) : (
        ""
      )}

      <div className="mb-2 grid grid-cols-5 gap-3">
        <div className="col-span-1">
          <Form.Item name="isESim" valuePropName="checked">
            <ToggleSwitch
              label="eSIM"
              onChange={(event) =>
                form.setFieldsValue({ isESim: !!event.target.checked })
              }
              name="isESim"
            />
          </Form.Item>
        </div>
        {hasModule(modules, CompanyModules.BUDGETMANAGER) && (
          <div className="col-span-1">
            <Form.Item name="isBudgetManager" valuePropName="checked">
              <ToggleSwitch
                label="Budgetmanager"
                checked={form.getFieldValue("isBudgetManager")}
                onChange={(event) =>
                  form.setFieldsValue({ isBudgetManager: event.target.checked })
                }
                name="isBudgetManager"
              />
            </Form.Item>
          </div>
        )}
      </div>
      {formValues.isBudgetManager && (
        <div>
          <ul className="mt-2">
            <li className="list-disc ml-5">
              <Typography tag={"p"} type={"default"}>
                U moet het intekeningsdocument onderaan uploaden.
              </Typography>
            </li>
          </ul>
          <div className="mt-2 mb-5 flex gap-2">
            <Button
              href="../../../BudgetManager_NL.pdf"
              download="IntekeningsdocumentBudgetmanager.pdf"
            >
              Download intekeningsdocument budgetmanager
            </Button>
          </div>
        </div>
      )}
      <div className="grid grid-cols-2 gap-x-2">
        <div className="col-span-2">
          <Form.Item
            label="Simkaart selecteren of ingeven"
            name="sim"
            rules={[
              {
                required: formValues.isESim ? false : true,
                message: "Selecteer een simkaart.",
              },
            ]}
          >
            <SelectSimcard
              onSelect={(value) =>
                form.setFieldsValue({ sim: value })
              }
              status={"inactive"}
              error={undefined}
              disabled={formValues.isESim}
            />
          </Form.Item>
        </div>
        <div className="col-span-2">
          <Form.Item
            label="Tariefplan selecteren"
            name="tarif"
            rules={[
              {
                required: true,
                message: "Selecteer een tariefplan.",
              },
            ]}
          >
            <SelectTarif
              onSelect={(_, option) => {
                form.setFieldsValue({
                  tarif: option,
                });
              }}
              defaultValue={undefined}
              type={"voice"}
            />
          </Form.Item>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-2">
        <Form.Item
          label="Vestiging"
          name="branch"
          rules={[
            {
              required: true,
              message: "Selecteer een vestiging.",
            },
          ]}
        >
          <BranchSelect
            handleSelect={handleBranchSelect}
            defaultValue={"Selecteer vestiging"}
          />
        </Form.Item>
        <Form.Item
          label="Uitvoeringsdatum"
          name="date"
          rules={[
            {
              required: true,
              message: "Selecteer een uitvoeringsdatum.",
            },
          ]}
        >
          <DateInput
            onChange={(date) => form.setFieldsValue({ date: date })}
            value={formValues.date}
            endDate={project ? project.proj_deadline : ""}
          />
        </Form.Item>
      </div>
      <div className="mb-5">
        <FileUpload
          fileTypes={["application/pdf"]}
          fileList={fileList}
          onChange={handleFileChange}
        />
      </div>
      <div className="mt-4">
        <FormButton form={true} text="Indienen" />
      </div>
      {loading ? <LoadingSpinner /> : ""}
    </Form>
  );
};

export default SimOnboardingForm;
