import React, { FC, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { TicketService } from "../../../services/tickets/Tickets";
import { updateAside } from "../../../state/component/AsideSlice";
import { setSimcard } from "../../../state/simdata/SimcardsSlice";
import { RootState } from "../../../state/store";
import { useTranslation } from "react-i18next";
import Cta from "../../buttons/Cta";
import Typography from "../../typography/Typography";
import { addToast } from "../../../state/toast/ToastSlice";
import { SimcardInterface } from "../../../interfaces/Simcard";

export interface Props {
  data: {
    sim: SimcardInterface;
  }
}

const RequestPuk: FC<Props> = ({ data: { sim }}) => {
  const [loading, setLoading] = useState(false);
  const ticketService = new TicketService();
  const dispatch = useDispatch();
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const { t } = useTranslation();

  const handleClick = async () => {
    setLoading(true);

    const prop = {
      sim_status: t("Puk aanvragen"),
    };
    dispatch(updateAside({ props: prop, object: "sim" }));
    dispatch(setSimcard({ props: prop, key: sim.key }));

    const description = `Aanvraag PUK-code: ${sim.sim_unique_nr}`;

    await ticketService.createTicketZendesk(companyId, {
      subject: `Aanvraag PUK-code: ${sim.sim_unique_nr} - @helpdesk`,
      description: description,
      department: "Telecom",
      linkedResource: {
        id: sim.sim_unique_nr,
        type: "TELECOM",
      },
    });

    dispatch(
      addToast({
        description: "Ticket aangemaakt",
        position: "bottomRight",
        style: "success",
      })
    );

    setLoading(false);
  };

  return (
    <div>
      <Typography type="label" tag="span">
        PIN-code vergeten of simkaart geblokkeerd?
      </Typography>
      <Cta text="PUK-code aanvragen" onClick={handleClick} loading={loading} />
    </div>
  );
};

export default RequestPuk;
