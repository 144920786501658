import React, { FC, ReactNode } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { RootState } from "../../state/store";
import Aside from "./Aside";
import { clearAside, clearAsides, clearChild, findAside, findLastAside, updateAside } from "../../state/component/AsideSlice";
import { X } from "react-feather";
import { title } from "process";
import Crumbs from "../navigation/Crumbs";

export interface Props {
    children?: ReactNode;
}

export const AsideManager: FC<Props> = ({ children }) => {
    const dispatch = useDispatch();
    const asideStore = useSelector((state: RootState) => state.aside);

    if (!asideStore.root) {
        return <></>;
    }

    const closeAllAsides = () => {
        dispatch(clearAsides());
    }

    const closeGivenAside = (title: string) => {
        dispatch(clearAside(title));
    }

    const switchAside = (title: string) => {
        dispatch(clearChild(title));
    }

    const components = [asideStore.root];
    let current = asideStore.root;
    while (current.child) {
        components.push(current.child);
        current = current.child;
    }

    components.forEach((component) => console.log(component.callback));

    return (
        <>
            <button
                className={`z-20 fixed top-0 left-0 bg-black opacity-25 w-full h-full block`}
                onClick={() => closeAllAsides()}
            ></button>
            <aside
                className={`z-40 fixed overflow-auto right-0 top-0 h-full dark:bg-gray-900 dark:text-slate-300 text-slate-900 transition-all ${"w-full block"
                    } max-w-screen-md bg-white`}
            >
                <button onClick={() => { closeGivenAside(current.title) }} className="float-right m-4 text-red-700">
                    <X />
                </button>
                <div className="p-10 md:p-20">
                    <Crumbs links={components} onclick={(title: string) => { switchAside(title) }} />
                    {components.map((component, index) => {
                        return (
                            <div className={component.title != current.title ? "hidden" : ""}>
                                <Aside key={index} callback={component.callback} component={component.component} title={component.title} data={{ ...component.data, title: component.title }} />
                            </div>
                        );
                    })}
                </div>
            </aside>
        </>);
};