import React, { FC, useEffect, useState } from "react";
import { UsersService } from "../../../services/users/Users";
import LoadingSpinner from "../../buttons/LoadingSpinner";
import TextHeader from "../../headers/TextHeader";
import FormButton from "../../buttons/FormButton";
import Accordeon from "../../accordeon/Accordeon";
import Typography from "../../typography/Typography";
import { DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import { ProjectsService } from "../../../services/project/Projects";
import { clearAsides, setRootAside } from "../../../state/component/AsideSlice";
import LicenseOverview from "../../licenses/LicenseOverview";
import { LicenseService } from "../../../services/licenses/Licenses";
import { setCurrentProject } from "../../../state/project/ProjectSlice";
import dayjs from "dayjs";
import { addToast } from "../../../state/toast/ToastSlice";
import DateInput from "../../inputs/dates/DateInput";
import { useTranslation } from "react-i18next";

export interface Props {
  data: {
    task: any;
  }
}

const LicenseOnboarding: FC<Props> = ({ data: { task } }) => {
  const { t } = useTranslation();
  const [licenses, setLicenses] = useState([]);
  const [otherLicenses, setOtherLicenses] = useState([]);
  const [selectedDate, setSelectedDate] = useState(dayjs(new Date()));
  const [loading, setLoading] = useState(false);
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const project = useSelector(
    (state: RootState) => state.project.currentProject
  );
  const selectedLicenses = useSelector(
    (state: RootState) => state.license.licenses
  );
  const licenseService = new LicenseService();
  const projectService = new ProjectsService();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchLicenses();
  }, []);

  const fetchLicenses = async () => {
    setLoading(true);
    try {
      const [companyLicenses, allLicenses] = await Promise.all([
        licenseService.getLicenses(companyId),
        licenseService.getAllLicenses(companyId),
      ]);

      setLicenses(companyLicenses);

      const other = allLicenses.filter(
        (lic) =>
          lic.lic_gebruikerslicenties &&
          !companyLicenses.some((compLic) => compLic.id_licentie === lic.id)
      );
      setOtherLicenses(other);
    } catch (error) {
      console.error("Error fetching licenses:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    const today = new Date();
    const filteredSelectedLicenses = Object.fromEntries(
      Object.entries(selectedLicenses).filter(
        ([id, { quantity }]) => quantity > 0
      )
    );

    const assignments = Object.entries(filteredSelectedLicenses).map(
      ([id, { quantity, name }]) => ({
        proj_omschrijving: `Licentie: ${name}, ${quantity} keer aan de gebruiker toewijzen, vanaf${
          selectedDate
            ? ` ${selectedDate.format("DD-MM-YYYY")}.`
            : ` ${today.toLocaleDateString()}.`
        }`,
        projFaseRegel_type: "cmctaak",
        projFaseRegel_extra: "CMC",
      })
    );

    dispatch(
      clearAsides()
    );

    try {
      const project = await projectService.addAssignments(
        assignments,
        task.phase_id,
        task.rule_id,
        companyId
      );

      dispatch(
        addToast({
          description: "Er is een aanvraag ingediend voor de licentie(s).",
          position: "bottomRight",
          style: "success",
          duration: 0,
        })
      );

      dispatch(setCurrentProject(project.result));
    } catch (error) {
      console.error("Error adding assignments:", error);
    }
  };

  const panels = [
    {
      key: "1",
      label: t("Andere beschikbare licenties"),
      children: <LicenseOverview licenses={otherLicenses} />,
    },
  ];

  return (
    <div className="overflow-hidden">
      <TextHeader title={"Licenties"} />
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <LicenseOverview licenses={licenses} />
          <Accordeon panels={panels} />
          <div className="mt-5 mb-2 w-1/2 mx-auto text-center">
            <Typography tag={"h1"} type={"semibold"}>
              Uitvoeringsdatum
            </Typography>
            <Typography tag={"h1"} type={"label"}>
              Laat de datum leeg als het vanaf vandaag mag.
            </Typography>
            <DateInput
              onChange={(date) => setSelectedDate(date)}
              value={selectedDate}
              endDate={project.proj_deadline}
            />
          </div>
          <div className="mt-5">
            <FormButton text={"Selectie bevestigen"} onClick={handleSubmit} />
          </div>
        </div>
      )}
    </div>
  );
};

export default LicenseOnboarding;
