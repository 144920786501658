import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";

interface AsideSliceState {
  root: AsideInterface | null;
}

export interface AsideInterface {
  component: string;
  title: string;
  data: Record<string, any>;
  child?: AsideInterface;
  parent?: string;
  callback?: (e: any) => void;
}

const initialState: AsideSliceState = {
  root: null,
};

export const findAside = (root: AsideInterface, title: string) => {
  let current: AsideInterface | undefined = root;

  while (current) {
    if (current.title === title) {
      return current;
    }

    current = current.child;
  }
}

export const findLastAside = (root: AsideInterface) => {
  let current = root;

  while(current.child) {
    
    current = current.child;
  }

  return current;
}

const asideSlice = createSlice({
  name: "aside",
  initialState,
  reducers: {
    setRootAside: (
      state,
      action: PayloadAction<{
        component: string;
        title: string;
        data?: Record<string, any>;
      }>
    ) => {
      state.root = {
        component: action.payload.component,
        title: action.payload.title,
        data: action.payload.data || {},
      }
    },
    updateAside: (
      state,
      action: PayloadAction<{ props: Record<string, any>; title: string }>
    ) => {

      const { props, title } = action.payload;
      if (!state.root) {
        return;
      }
      
      const toUpdate = findAside(state.root, title);
      if (!toUpdate) {
        return;
      }

      toUpdate.data = {
        ...toUpdate.data,
        ...props,
      }
    },
    clearAsides: (state) => {
      state.root = null;
    },
    clearAside: (state, action: PayloadAction<string>) => {
      const title = action.payload;
      if (!state.root) {
        return;
      }

      if (state.root.title === title) {
        state.root = null;
        return;
      }

      const aside = findAside(state.root, title);
      if (!aside?.parent) {
        return;
      }

      const parent = findAside(state.root, aside.parent);
      if (parent?.child) {
        parent.child = undefined;
      }
    },
    clearChild(state, action: PayloadAction<string>) {
      const title = action.payload;
      if (!state.root) {
        return;
      }

      if (state.root.title === title) {
        state.root.child = undefined;
        return;
      }

      const aside = findAside(state.root, title);
      if (!aside) {
        return;
      }
      
      aside.child = undefined;
    },
    stackAside: (state, action: PayloadAction<AsideInterface>) => {
      if (!state.root) {
        return;
      }

      const lastAside = findLastAside(state.root);

      lastAside.child = {
        component: action.payload.component,
        title: action.payload.title,
        data: action.payload.data || {},
        callback: action.payload.callback,
        parent: lastAside.title
      }
    }
  },
});

export const { setRootAside, stackAside, updateAside, clearAsides, clearAside, clearChild } =
  asideSlice.actions;
export default asideSlice.reducer;
