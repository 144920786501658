import React, { FC, useState } from "react";
import Form from "../Form";
import SelectTarif from "../../../organisms/simcards/SelectTarif";
import Typography from "../../typography/Typography";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import { setSimcard } from "../../../state/simdata/SimcardsSlice";
import { updateAside } from "../../../state/component/AsideSlice";
import { TicketService } from "../../../services/tickets/Tickets";
import { TarifService } from "../../../services/simcards/Tarifs";
import { addToast } from "../../../state/toast/ToastSlice";
import { SimcardInterface } from "../../../interfaces/Simcard";

type props = {
  currentTarifId: number;
  currentValue: string;
  data: {
    sim: SimcardInterface;
  }
};

const ChangeTarif: FC<props> = ({ currentTarifId, currentValue, data: { sim } }) => {
  const [tarif, setTarif] = useState(currentTarifId);
  const [loading, setLoading] = useState(false);
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const ticketService = new TicketService();
  const service = new TarifService();
  const dispatch = useDispatch();

  const handleChange = async (value, option) => {
    setTarif(value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const selectedTarif = await service.getTarif(tarif, companyId);
    const prop = {
      tariefplan: selectedTarif,
      trf_id: currentTarifId,
      sim_status: "Tarief wijziging",
    };
    dispatch(updateAside({ props: prop, object: "sim" }));
    dispatch(setSimcard({ props: prop, key: sim.key }));

    const description = `Aanvraag tarief wijziging: ${selectedTarif.trf_naam} | GSM-Nummer: ${asideData.sim.sim_gsm_nr} | Simkaart#: ${asideData.sim.sim_unique_nr} | Huidige tariefplan: ${asideData.sim.tariefplan.trf_naam}`;
    
    await ticketService.createTicketZendesk(companyId, {
      subject: `Aanvraag tarief wijziging: ${selectedTarif.trf_naam} - @helpdesk`,
      description: description,
      department: "Telecom",
      linkedResource: {
        id: sim.sim_unique_nr,
        type: "TELECOM",
      },
    });

    dispatch(
      addToast({
        description: "Ticket aangemaakt",
        position: "bottomRight",
        style: "success",
      })
    );

    setLoading(false);
  };

  return (
    <div>
      <Form
        buttonLabel="Indienen"
        handleSubmit={handleSubmit}
        gridSize="grid-cols-2"
        loading={loading}
      >
        <Typography tag="label" type="label">
          Selecteer nieuw tariefplan
        </Typography>
        <SelectTarif
          onSelect={(value, option) => handleChange(value, option)}
          defaultValue={currentValue}
          type="Voice,Data"
        />
      </Form>
    </div>
  );
};

export default ChangeTarif;
