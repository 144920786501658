import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { Form, Button } from "antd";
import { AssetsService } from "../../services/assets/Assets";
import { clearAsides, setRootAside } from "../../state/component/AsideSlice";
import LoadingSpinner from "../../components/buttons/LoadingSpinner";
import FormField from "../../components/inputs/text/FormField";
import FormButton from "../../components/buttons/FormButton";
import { useDispatch, useSelector } from "react-redux";
import TextHeader from "../../components/headers/TextHeader";
import SearchTable from "../../components/inputs/search/SearchTable";
import Table from "../../components/tables/Table";
import TableItem from "../../components/tables/TableItem";
import Checkmark from "../../components/tags/Checkmark";
import Typography from "../../components/typography/Typography";
import { addToast } from "../../state/toast/ToastSlice";
import { createHardware } from "../../state/hardware/HardwareSlice";
import { RootState } from "../../state/store";

const AddHardware: FC = (hardware) => {
  const [form] = Form.useForm(); // Ant Design form instance
  const [search, setSearch] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [remoteManaged, setRemoteManaged] = useState([]);
  const [filteredRemoteManaged, setFilteredRemoteManaged] = useState([]);
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const service = new AssetsService();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const deviceIds = Object.values(hardware.data.hardware).map(
          (item) => item.device_id
        );

        const remoteManagedData = await service.fetchNinjaCompanyDevices(
          companyId
        );

        const filteredRemoteManaged = remoteManagedData.filter(
          (device) => !deviceIds.includes(String(device.id))
        );

        filteredRemoteManaged.sort((a, b) => {
          if (!a.displayName && b.displayName) return -1;
          if (a.displayName && !b.displayName) return 1;
          return 0;
        });

        setRemoteManaged(filteredRemoteManaged);
        setFilteredRemoteManaged(filteredRemoteManaged);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const onCreate = async (values: any) => {
    try {
      const hardware = {
        serialNumber: values.serial,
        name: values.name,
        category: values.category,
      };
      const newHardware = await service.addHardware(companyId, hardware);
      const newHardwareData = await service.assetDetail(companyId, newHardware.assetId);

      // need to return necessary from backend

      dispatch(createHardware(newHardwareData));
      dispatch(
        addToast({
          description: "Het apparaat is aangemaakt.",
          position: "bottomRight",
          style: "success",
        })
      );
      closeAside();
    } catch (error) {
      console.error("Error creating hardware:", error);
    }
  };

  const closeAside = () => {
    dispatch(
      clearAsides()
    );
  };

  const onNinjaAdd = async (device_id: number) => {
    setConfirmLoading(true);
    const newHardware = await service.addHardware(companyId, {
      deviceId: device_id,
    });

    const newHardwareData = await service.assetDetail(companyId, newHardware.assetId);
    dispatch(createHardware(newHardwareData));

    // Filter out the device with device_id from remoteManaged
    const updatedRemoteManaged = remoteManaged.filter(
      (device) => device.id !== device_id
    );
    setRemoteManaged(updatedRemoteManaged);

    // Filter out the device with device_id from filteredRemoteManaged
    const updatedFilteredRemoteManaged = filteredRemoteManaged.filter(
      (device) => device.id !== device_id
    );
    setFilteredRemoteManaged(updatedFilteredRemoteManaged);

    setConfirmLoading(false);

    dispatch(
      addToast({
        description: "Het apparaat is toegevoegd.",
        position: "bottomRight",
        style: "success",
      })
    );
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearch(value);
    filterRemoteManaged(value);
  };

  const filterRemoteManaged = (value: string) => {
    const filteredServices = remoteManaged.filter((hardware) =>
      hardware.systemName.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredRemoteManaged(filteredServices);
  };

  const tableHeaders: object = ["Hardware", "Naam", "Online", "Acties"];

  return (
    <div>
      <TextHeader title={"Hardware toevoegen"} />
      <Form
        labelAlign="left"
        layout="vertical"
        form={form} // Pass Ant Design form instance to Form component
        onFinish={onCreate}
      >
        <div className="mt-5 grid grid-cols-2 gap-x-5">
          <div>
            <FormField
              type="text"
              label="Naam product"
              placeholder=""
              name="name"
              form={form}
              isRequired={true}
            />
          </div>
          <div>
            <FormField
              type="text"
              label="Categorie"
              placeholder=""
              name="category"
              form={form}
              isRequired={true}
            />
          </div>
          <div>
            <FormField
              type="text"
              label="Serienummer"
              placeholder=""
              name="serial"
              form={form}
            //   isRequired={true}
            />
          </div>
          <div className="col-span-2 my-2">
            <FormButton form={true} text={"Aanmaken"} />
          </div>
        </div>
      </Form>
      {loading ? (
        <LoadingSpinner />
      ) : remoteManaged.length > 0 ? (
        <div>
          <div className="mt-5">
            <Typography tag={"h1"} type={"title"}>
              Remote managed
            </Typography>
            <SearchTable
              handleChange={handleChange}
              placeholder="Hardware zoeken..."
              value={search}
            />
            <Table header={tableHeaders}>
              {filteredRemoteManaged?.map((hardware: any, index) => (
                <tr key={index}>
                  <TableItem>
                    {/* {<TruncateString text={service.displayName} limit={30} />} */}
                    {hardware.systemName}
                  </TableItem>
                  <TableItem>
                    {hardware.displayName}
                    {/* <DateFormatted date={hardware?.lastContact * 1000} />
                    &nbsp;
                    <Time time={hardware?.lastContact * 1000} /> */}
                  </TableItem>
                  <TableItem>
                    <Checkmark check={hardware.offline ? 0 : 1} />
                  </TableItem>
                  <TableItem>
                    <Button
                      className="bg-blue-700 text-white"
                      onClick={() => onNinjaAdd(hardware.id)}
                      loading={confirmLoading}
                    >
                      Toevoegen
                    </Button>
                  </TableItem>
                </tr>
              ))}
            </Table>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default AddHardware;
