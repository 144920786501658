import React, { FC, useEffect, useState } from "react";
import Typography from "../../components/typography/Typography";
import SearchTable from "../../components/inputs/search/SearchTable";
import Table from "../../components/tables/Table";
import TableItem from "../../components/tables/TableItem";
import LoadingSpinner from "../../components/buttons/LoadingSpinner";
import { UsersService } from "../../services/users/Users";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { Button } from "antd";
import { UserPlus } from "react-feather";
import EmployeeThumb from "../../components/EmployeeThumb";
import { ProjectsService } from "../../services/project/Projects";

type Props = {
  currentUsers: [];
  data: {
    users: any;
  }
};

const AddProjectUser: FC<Props> = ({ currentUsers, data: { users } }) => {
  const [projectUsersArray, setProjectUsersArray] = useState(
    Object.values(users).filter(
      (item) => typeof item === "object" && item.name
    )
  );
  const project = useSelector(
    (state: RootState) => state.project.currentProject
  );
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );

  const [availableEmployees, setAvailableEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  const [removalLoading, setRemovalLoading] = useState<boolean>(false);
  const [additionLoading, setAdditionLoading] = useState<{
    [key: string]: boolean;
  }>({});

  const userService = new UsersService();
  const projectsService = new ProjectsService();

  useEffect(() => {
    setLoading(true);
    if (companyId) {
      const fetchData = async () => {
        const users = await userService.getUsers(companyId, "");

        const filteredUsers = users.filter((user) => {
          const isExistingUser = projectUsersArray.some((projectUser) => {
            return (
              projectUser.geb_id === user.geb_id ||
              projectUser.med_id === user.geb_emp_id
            );
          });
          return !isExistingUser;
        });

        setAvailableEmployees(filteredUsers);
        setLoading(false);
      };

      fetchData();
    }
  }, [companyId]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const onRemoveUser = async (employee: {
    med_id?: number;
    geb_id?: number;
  }) => {
    setRemovalLoading(true);

    await projectsService.deleteProjectUser(
      project.proj_id,
      employee.med_id ? null : employee.geb_id,
      employee.med_id || null,
      companyId
    );

    const updatedUsers = projectUsersArray.filter((user) => {
      if (employee.med_id) {
        return user.med_id !== employee.med_id;
      }
      if (employee.geb_id) {
        return user.geb_id !== employee.geb_id;
      }
      return true;
    });

    setProjectUsersArray(updatedUsers);
    setRemovalLoading(false);
  };

  const onAddUser = async (employee: Object) => {
    const userKey = employee.geb_emp_id
      ? employee.geb_emp_id.toString()
      : employee.geb_id.toString();

    setAdditionLoading((prev) => ({ ...prev, [userKey]: true }));

    const projectUser = employee.geb_emp_id
      ? { prj_med_id: employee.geb_emp_id }
      : { prj_geb_id: employee.geb_id };

    const user = await projectsService.postProjectUser(
      project.proj_id,
      projectUser,
      companyId
    );

    const newUser = employee.geb_emp_id
      ? user[0]
      : {
          ...user[0],
          name: `${user[0].geb_voornaam} ${user[0].geb_naam}`,
        };

    const updatedAvailableEmployees = availableEmployees.filter(
      (emp) =>
        (emp.geb_emp_id && emp.geb_emp_id !== employee.geb_emp_id) ||
        (emp.geb_id && emp.geb_id !== employee.geb_id)
    );

    setAvailableEmployees(updatedAvailableEmployees);

    setProjectUsersArray([...projectUsersArray, newUser]);
    setAdditionLoading((prev) => ({ ...prev, [userKey]: false }));
  };

  const tableHeaders: Array<string> = ["Naam", "E-mail", "Acties"];

  return (
    <div>
      {projectUsersArray.length > 0 && (
        <>
          <Typography tag="h3" type="header">
            Huidige gebruikers
          </Typography>
          <div className="flex flex-col gap-3 mt-3 mb-5">
            {projectUsersArray.map((user, index) => (
              <div key={index} className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  {user.photo ? (
                    <EmployeeThumb
                      image={user.photo}
                      name={user.name}
                      size={10}
                    />
                  ) : (
                    <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600 border-2 border-white dark:border-slate-800">
                      <span className="font-medium text-gray-800 dark:text-gray-200">
                        {user.name.charAt(0)}
                      </span>
                    </div>
                  )}
                  <Typography tag="p" type="default">
                    {user.name}
                  </Typography>
                </div>
                <Button
                  onClick={() => onRemoveUser(user)}
                  danger
                  loading={removalLoading}
                >
                  Remove
                </Button>
              </div>
            ))}
          </div>
        </>
      )}
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Typography tag="h3" type="title">
            Gebruikers toevoegen
          </Typography>
          <div className="overflow-y-hidden border dark:border-gray-700 rounded-lg mt-3">
            <SearchTable
              handleChange={handleSearchChange}
              placeholder="Gebruiker zoeken..."
              value={searchQuery}
            />
            <Table header={tableHeaders}>
              {availableEmployees
                .filter((employee) =>
                  `${employee.geb_voornaam} ${employee.geb_naam}`
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase())
                )
                .map((employee, index) => (
                  <tr key={index}>
                    <TableItem type="tiny">
                      {employee.geb_voornaam} {employee.geb_naam}
                    </TableItem>
                    <TableItem type="tiny">{employee.email}</TableItem>
                    <TableItem type="tiny">
                      {additionLoading[
                        employee.geb_emp_id || employee.geb_id
                      ] ? (
                        <LoadingSpinner />
                      ) : (
                        <button
                          onClick={() => onAddUser(employee)}
                          className="text-green-600 hover:text-green-800 dark:text-green-500 dark:hover:text-green-700 flex items-center"
                        >
                          <UserPlus size={14} />
                        </button>
                      )}
                    </TableItem>
                  </tr>
                ))}
              {availableEmployees.length === 0 && (
                <tr>
                  <TableItem type="tiny">Geen gebruikers gevonden</TableItem>
                </tr>
              )}
            </Table>
          </div>
        </>
      )}
    </div>
  );
};

export default AddProjectUser;
