import React, { FC, useEffect, useState } from "react";
import { Form, Select } from "antd";
import FormField from "../../inputs/text/FormField";
import { useDispatch, useSelector } from "react-redux";
import { addToast } from "../../../state/toast/ToastSlice";
import FormButton from "../../buttons/FormButton";
import { BranchesService } from "../../../services/branches/Branches";
import {
  addOptions,
  createBranch,
  selectBranch,
  updateBranch,
} from "../../../state/branch/BranchSlice";
import SelectBox from "../../inputs/selectbox/SelectBox";
import LoadingSpinner from "../../buttons/LoadingSpinner";
import { clearAside } from "../../../state/component/AsideSlice";
import { useTranslation } from "react-i18next";
import { RootState } from "../../../state/store";

type Branch = {
  branch: any;
  source?: string;
  submit?: string;
  callback?: () => void;
  title?: string;
};

const BranchForm: FC<Branch> = ({ branch, title, source = "", submit, callback }) => {
  const { t } = useTranslation();
  const service = new BranchesService();
  const [form] = Form.useForm();
  const [branchData, setBranchData] = useState(branch);
  const [formLoading, setFormLoading] = useState(true);
  const dispatch = useDispatch();

  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );

  const countryOptions = [
    { value: "België", label: t("België") },
    { value: "Nederland", label: t("Nederland") },
    { value: "Frankrijk", label: t("Frankrijk") },
    { value: "Duitsland", label: t("Duitsland") },
  ];

  useEffect(() => {
    setFormLoading(true);

    // Perform asynchronous operation
    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 100));

      // Update groupData after the asynchronous operation
      setBranchData(branch);
      form.setFieldsValue(branch);
      setFormLoading(false);
    };

    // Call the fetchData function to start the asynchronous operation
    fetchData();
  }, [branch]);

  const handleSelect = (value: string, label: string) => {
    setBranchData((prev) => ({
      ...prev,
      ves_land: value,
    }));
  };

  const handleSubmit = async (values: any) => {
    if (source === "AddressOverviewContainer") {
      const updatedValues = {
        ves_id: 0,
        ...values,
        ves_land: branchData.ves_land ?? "België",
      };
      dispatch(selectBranch(updatedValues));
      dispatch(createBranch(updatedValues));

      const formattedValues = {
        value: 0,
        label: `${values.ves_straatNaam} ${values.ves_straatNr}, ${
          values.ves_gemeente
        }, ${branchData.ves_land ?? "België"}`,
      };
      dispatch(addOptions([formattedValues]));
      dispatch(
        addToast({
          description: "Uw adres is toegevoegd.",
          position: "bottomRight",
          style: "success",
        })
      );
    } else if (Object.keys(branch).length === 0) {
      onCreate(values);
    } else {
      onUpdate(values);
    }
  };

  const onUpdate = async (values: any) => {
    console;
    const updatedValues = {
      ...values,
      ves_land: branchData.ves_land ?? "België",
    };
    await service.updateBranch(companyId, branch.ves_id, updatedValues);

    dispatch(updateBranch({ id: branch.ves_id, updatedData: updatedValues }));

    dispatch(
      addToast({
        description: "De vestiging is aangepast.",
        position: "bottomRight",
        style: "success",
      })
    );

    dispatch(
      clearAside(title || "branchDetail")
    );
  };

  const onCreate = async (values: any) => {
    const updatedValues = {
      ...values,
      ves_land: branchData.ves_land ?? "België",
    };
    // Update database
    const newBranch = await service.createBranch(companyId, updatedValues);

    // Update redux store
    dispatch(createBranch({ ...updatedValues, ves_id: newBranch.branchId }));

    // Toast notif
    dispatch(
      addToast({
        description: "De vestiging is aangemaakt.",
        position: "bottomRight",
        style: "success",
      })
    );

    if (callback) {
      callback();
    }

    dispatch(
      clearAside(title || "branchDetail")
    );
  };

  return (
    <div>
      {formLoading ? (
        <LoadingSpinner />
      ) : (
        <Form
          labelAlign="left"
          layout="vertical"
          form={form}
          initialValues={branch}
          onFinish={handleSubmit}
        >
          <div className="mt-5 grid grid-cols-3 gap-x-5">
            {source === "AddressOverviewContainer" ? (
              ""
            ) : (
              <div className="col-span-2">
                <FormField
                  type="text"
                  label="Label"
                  placeholder=""
                  name="ves_name"
                  form={form}
                  // isRequired={true}
                />
              </div>
            )}
            <div className="col-span-2">
              <FormField
                type="text"
                label="Straatnaam"
                placeholder=""
                name="ves_straatNaam"
                form={form}
                isRequired={true}
              />
            </div>
            <div className="col-span-1">
              <FormField
                type="number"
                label="Huisnummer"
                placeholder=""
                name="ves_straatNr"
                form={form}
                isRequired={true}
              />
            </div>

            <div className="col-span-2">
              <FormField
                type="text"
                placeholder=""
                label="Gemeente"
                name="ves_gemeente"
                form={form}
                isRequired={true}
              />
            </div>
            <div className="col-span-1">
              <FormField
                type="number"
                placeholder=""
                label="Postcode"
                name="ves_postcode"
                form={form}
                isRequired={true}
              />
            </div>
            <div className="col-span-1 mb-3">
              {/* <FormField
            type="text"
            placeholder=""
            label="Land *"
            name="ves_land"
            form={form}
            isRequired={true}
          /> */}
              <SelectBox
                options={countryOptions}
                defaultValue={branchData.ves_land ?? "België"}
                onChange={handleSelect}
                loading={undefined}
              />
            </div>
            <div className="col-span-3 my-2">
              <FormButton
                form={true}
                text={Object.keys(branch).length === 0 ? "Aanmaken" : "Opslaan"}
              />
            </div>
          </div>
        </Form>
      )}
    </div>
  );
};

export default BranchForm;
