import React, { useState, useEffect, FC } from "react";
import { TicketService } from "../../../services/tickets/Tickets";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import TicketTable from "../../../components/tickets/TicketTable";
import LoadingSpinner from "../../../components/buttons/LoadingSpinner";

type props = {
  simId: number;
};

const SimTickets: FC<props> = ({ simId }) => {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const service = new TicketService();

  useEffect(() => {
    const fetchTickets = async () => {
      setLoading(true);
      try {
        const fetchedTickets = await service.getTickets(
          companyId,
          "TELECOM",
          simId
        );
        setTickets(fetchedTickets.zendesk);
      } catch (error) {
        console.error("Error fetching tickets:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTickets();
  }, [companyId, simId]);

  return (
    <>{loading ? <LoadingSpinner /> : <TicketTable tickets={tickets} />}</>
  );
};

export default SimTickets;
