import { FC } from "react";
import TextHeader from "../../../components/headers/TextHeader";
import React from "react";
import BranchForm from "../../../components/forms/branches/BranchForm";

interface Props {
  data: any;
  callback: () => void;
}

const BranchCreateContainer: FC<Props> = ({ data, callback }) => {
  console.log(callback);
  console.log(data);
  return (
    <div>
      <TextHeader title="Aanmaken" subtitle="Vestiging" />
      <BranchForm branch={{}} title={data.title} callback={callback}></BranchForm>
    </div>
  );
};

export default BranchCreateContainer;
