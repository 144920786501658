import React, { useState } from "react";
import { Button, Form } from "antd";
import Typography from "../../../components/typography/Typography";
import BranchSelect from "../../../components/branches/BranchSelect";
import { TicketService } from "../../../services/tickets/Tickets";
import { useDispatch, useSelector } from "react-redux";
import { addToast } from "../../../state/toast/ToastSlice";
import { useTranslation } from "react-i18next";
import { RootState } from "../../../state/store";
import { clearAsides, setRootAside } from "../../../state/component/AsideSlice";
import FormButton from "../../../components/buttons/FormButton";
import FormField from "../../../components/inputs/text/FormField";
import PopoverForm from "../../../components/popover/PopoverForm";
import BranchForm from "../../../components/forms/branches/BranchForm";

const RequestNewSimcards = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const service = new TicketService();
  const dispatch = useDispatch();

  const handleSubmit = async (values: any) => {
    setLoading(true);

    await service.createTicketZendesk(companyId, {
      subject: `Aanvraag nieuwe simkaarten`,
      description: `Aanvraag voor ${values.amount} nieuwe simkaarten op te sturen naar: ${values.address}`,
      department: "Telecom",
    });

    dispatch(
      addToast({
        description: [
          "Aanvraag nieuwe simkaarten",
          { address: values.address },
        ],
        style: "success",
        position: "bottomRight",
      })
    );

    dispatch(clearAsides());
    setLoading(false);
  };

  return (
    <Form
      form={form}
      labelAlign="left"
      layout="vertical"
      onFinish={handleSubmit}
    >
      <div className="block mb-4">
        <Typography tag="span" type="default">
          Simkaarten worden opgestuurd
        </Typography>
      </div>

      <div className="mt-2">
        <FormField
          form={form}
          type="number"
          label="Aantal simkaarten"
          name="amount"
          placeholder="20"
          isRequired={true}
        />
      </div>

      <Form.Item
        label={t("Leveradres")}
        name="address"
        rules={[{ required: true, message: "Selecteer een leveradres" }]}
      >
        <BranchSelect
          loading={loading}
          handleSelect={(event, option) =>
            form.setFieldsValue({ address: option.label })
          }
          defaultValue={t("Selecteer adres")}
        />
      </Form.Item>
      <div>
        <PopoverForm
          form={
            <BranchForm
              branch={{}}
              source="AddressOverviewContainer"
            ></BranchForm>
          }
          button={<Button>Ander adres</Button>}
          title="Ander adres"
        />
      </div>

      <div className="col-span-3 mt-4">
        <FormButton form={true} text="Aanvraag indienen" />
      </div>
    </Form>
  );
};

export default RequestNewSimcards;
