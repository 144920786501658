import React, { useEffect, useState } from "react";
import {
  BarChart2,
  Briefcase,
  Phone,
  Layers,
  MessageSquare,
  ShoppingBag,
  Globe,
  ShoppingCart,
  Search,
  Menu as HamburgerIcon,
  X as CloseIcon,
  Users,
  Settings,
  LogOut,
  Clipboard,
  ChevronLeft,
  ChevronRight,
} from "react-feather";
import { navigate } from "gatsby";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { hasRole, UserRoles } from "../../utils/auth/AuthorizationUtility";
import { setRootAside } from "../../state/component/AsideSlice";
import { toggleSearchBar } from "../../state/search/searchBarSlice";
import MobileNav from "./MobileNav";
import DesktopNav from "./DesktopNav";
import { Dropdown, MenuProps } from "antd";
import i18n from "i18next";
import { addToast } from "../../state/toast/ToastSlice";
import { useTranslation } from "react-i18next";
import { UsersService } from "../../services/users/Users";
import useDarkSide from "../../utils/useDarkSlide";
import { clearRoles } from "../../state/auth/AuthSlice";
import { clearProfile } from "../../state/profile/ProfileSlice";
import {
  setSideBarOpen,
  toggleNotifications,
} from "../../state/notification/NotificationSlice";
import { DarkModeSwitch } from "react-toggle-dark-mode";
import { useLocation } from "@reach/router";

const Sidebar: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [colorTheme, setColorTheme] = useDarkSide();

  const service = new UsersService();

  const roles = useSelector((state: RootState) => state.auth.roles);
  const profile = useSelector((state: RootState) => state.profile);
  const shoppingCartData = useSelector((state: RootState) => state.cart);
  const cyod = useSelector((state: RootState) => state.cyod);
  const sidebarOpen = useSelector(
    (state: RootState) => state.notification.sideBarOpen
  );

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const location = useLocation();
  const isShopPage = location.pathname.startsWith("/shop");

  const toggleSidebar = () => {
    const newSidebarState = !sidebarOpen;
    dispatch(setSideBarOpen(newSidebarState));
  };

  const calculateTotalItems = () => {
    let totalItems = shoppingCartData.totalItems;
    Object.values(cyod.selectedProducts).forEach((product) => {
      totalItems += 1;
      totalItems += product.accessories?.length;
    });
    return totalItems;
  };

  const openShoppingCartAside = () => {
    dispatch(
      setRootAside({
        component: "shoppingCart",
        data: shoppingCartData,
        title: "Winkelwagen",
      })
    );
  };

  const openCreateTicket = () => {
    dispatch(
      setRootAside({
        component: "ticketCreate",
        data: {},
        title: "Ticket aanmaken",
      })
    );
  };

  const handleSearchClick = () => {
    dispatch(toggleSearchBar());
  };

  const onChangeLanguage = (language: string) => {
    i18n.changeLanguage(language);

    dispatch(
      addToast({
        description: `De taal wordt gewijzigd, gelieve te wachten.`,
        position: "bottomRight",
        style: "success",
        duration: 5,
      })
    );
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        event.ctrlKey &&
        event.key === "k" &&
        hasRole(roles, UserRoles.ADMIN)
      ) {
        event.preventDefault();
        handleSearchClick();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [roles]);

  const logOut = async () => {
    await service.logout();
    dispatch(clearRoles());
    dispatch(clearProfile());
    navigate("/");
  };

  const toggleDarkModeHandler = (checked) => {
    setColorTheme(checked ? "dark" : "light");
  };

  type MenuItem = Required<MenuProps>["items"][number];

  const upperMenuItems: MenuItem[] = [
    ...(hasRole(roles, UserRoles.ADMIN)
      ? [
          {
            key: "1",
            icon: <Search size={18} />,
            label: t("Zoeken"),
            onClick: handleSearchClick,
          },
        ]
      : []),
    ...(isShopPage
      ? [
          {
            key: "2",
            icon: (
              <div className="relative hover:cursor-pointer text-white">
                <ShoppingCart size={18} />
                {calculateTotalItems() > 0 && (
                  <div className="absolute inline-flex items-center justify-center w-4 h-4 text-xs text-white bg-red-500 border border-white rounded-full -top-1.5 -end-2">
                    {calculateTotalItems()}
                  </div>
                )}
              </div>
            ),
            label: t("Winkelwagen"),
            onClick: openShoppingCartAside,
          },
        ]
      : []),
  ];

  const middleMenuItems: MenuItem[] = [
    {
      key: "1",
      icon: <BarChart2 size={18} />,
      label: t("Dashboard"),
      onClick: () =>
        navigate(
          hasRole(roles, UserRoles.ADMIN)
            ? "/"
            : `/user/dashboard/overview?user=${profile.userId}`
        ),
    },
    ...(hasRole(roles, UserRoles.ADMIN)
      ? [
          {
            key: "sub1",
            label: t("Activa"),
            icon: <Briefcase size={18} />,
            children: [
              {
                key: "2",
                label: t("Hardware"),
                onClick: () => navigate("/hardware/overview"),
              },
              {
                key: "3",
                label: t("Licenties"),
                onClick: () => navigate("/licenses/overview"),
              },
            ],
          },
          {
            key: "4",
            icon: <Phone size={18} />,
            label: t("Telecom"),
            onClick: () => navigate("/telecom/sim/overview"),
          },
          {
            key: "5",
            icon: <Layers size={18} />,
            label: t("Projecten"),
            onClick: () => navigate("/project/overview"),
          },
          {
            key: "sub2",
            icon: <MessageSquare size={18} />,
            label: t("Tickets"),
            children: [
              {
                key: "6",
                label: t("Overzicht"),
                onClick: () => navigate("/tickets/overview"),
              },
              {
                key: "7",
                label: t("+ Ticket"),
                onClick: () => openCreateTicket(),
              },
            ],
          },
        ]
      : []),
    {
      key: "8",
      icon: <ShoppingBag size={18} />,
      label: t("Shop"),
      onClick: () => navigate("/shop/categories/overview"),
    },
    ...(hasRole(roles, UserRoles.ADMIN)
      ? [
          {
            key: "sub3",
            icon: <Users size={18} />,
            label: t("Bedrijf"),
            children: [
              {
                key: "9",
                label: t("Gebruikers"),
                onClick: () => navigate("/settings/users/overview"),
              },
              {
                key: "10",
                label: t("Groepen"),
                onClick: () => navigate("/settings/groups/overview"),
              },
              {
                key: "11",
                label: t("Vestigingen"),
                onClick: () => navigate("/settings/branches/overview"),
              },
            ],
          },
          {
            key: "12",
            icon: <Settings size={18} />,
            label: t("Instellingen"),
            onClick: () => navigate("/settings/settings/overview"),
          },
        ]
      : []),
  ];

  const lowerMenuItems: MenuItem[] = [
    {
      key: "sub1",
      label: t("Taal"),
      icon: <Globe size={18} />,
      children: [
        {
          key: "1",
          label: "NL",
          onClick: () => onChangeLanguage("nl"),
        },
        {
          key: "2",
          label: "FR",
          onClick: () => onChangeLanguage("fr"),
        },
        {
          key: "3",
          label: "EN",
          onClick: () => onChangeLanguage("en"),
        },
      ],
    },
  ];

  const profileItems: MenuItem[] = [
    {
      key: "1",
      icon: (
        <DarkModeSwitch
          checked={colorTheme === "dark"}
          onChange={toggleDarkModeHandler}
          size={18}
          sunColor="#F4BD29"
          moonColor="#ffffff"
        />
      ),
      label: t("Donkere modus"),
      onClick: () => toggleDarkModeHandler(colorTheme !== "dark"),
    },
    {
      key: "2",
      icon: <Clipboard size={18} />,
      label: t("Mijn bestellingen"),
      onClick: () => navigate("/order/overview"),
    },
    {
      key: "3",
      danger: true,
      icon: <LogOut size={18} />,
      label: t("Afmelden"),
      onClick: () => logOut(),
    },
  ];

  return (
    <div className="relative">
      <div className="md:hidden fixed top-0 left-0 z-[60] p-3.5">
        <button
          onClick={() => setMenuOpen(!menuOpen)}
          aria-label="Toggle navigation"
        >
          {menuOpen ? (
            <CloseIcon size={24} color="white" />
          ) : (
            <HamburgerIcon size={24} />
          )}
        </button>
      </div>
      {menuOpen && (
        <div className="md:hidden fixed top-0 right-0 z-[60] p-2">
          <Dropdown menu={{ items: profileItems }} trigger={["click"]}>
            <button onClick={(e) => e.preventDefault()}>
              <div className={`flex`}>
                <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full">
                  <span className="font-medium text-gray-600">
                    {profile.firstName.charAt(0)}
                    {profile.lastName.charAt(0)}
                  </span>
                </div>
              </div>
            </button>
          </Dropdown>
        </div>
      )}
      <MobileNav
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        menuItems={[...upperMenuItems, ...middleMenuItems, ...lowerMenuItems]}
      />
      <DesktopNav middleMenuItems={middleMenuItems} />
      <button
        onClick={() => toggleSidebar()}
        className={`hidden md:block absolute -right-5 top-1/2 transform -translate-y-1/2 bg-blue-700 text-white py-3 rounded-r-xl ${
          sidebarOpen ? "hover:-right-4" : "hover:-right-6 hover:pl-1"
        } transition-all`}
      >
        {sidebarOpen ? <ChevronLeft /> : <ChevronRight />}
      </button>
    </div>
  );
};

export default Sidebar;
