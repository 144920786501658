import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface BranchState {
  branches: any[];
  selectedBranch: any;
  invoiceAddress: any;
  options: any[];
}

const initialState: BranchState = {
  branches: [],
  selectedBranch: { undefined },
  invoiceAddress: { undefined },
  options: [],
};

const branchSlice = createSlice({
  name: "branch",
  initialState,
  reducers: {
    setBranches: (state, action: PayloadAction<object[]>) => {
      const incomingBranches = action.payload;

      incomingBranches.forEach((incomingBranch) => {
        const existingIndex = state.branches.findIndex(
          (branch) => branch.ves_id === incomingBranch.ves_id
        );

        if (existingIndex !== -1) {
          // Replace the existing branch
          state.branches[existingIndex] = incomingBranch;
        } else {
          // Add the new branch
          state.branches.push(incomingBranch);
        }
      });
    },
    selectBranch: (state, action: PayloadAction<object>) => {
      state.selectedBranch = action.payload;
    },
    selectInvoiceAddress: (state, action: PayloadAction<object>) => {
      state.invoiceAddress = action.payload;
    },
    updateBranch: (state, action) => {
      const { id, updatedData } = action.payload;
      const existingBranch = state.branches.find(
        (branch) => branch.ves_id === id
      );
      if (existingBranch) {
        Object.assign(existingBranch, updatedData);
      }
    },
    createBranch: (state, action: PayloadAction<object>) => {
      const newBranch = action.payload;
      const existingIndex = state.branches.findIndex(
        (branch) => branch.ves_id === newBranch.ves_id
      );

      if (existingIndex !== -1) {
        // Replace the existing branch with updated values
        state.branches[existingIndex] = {
          ...state.branches[existingIndex],
          ...newBranch,
        };
      } else {
        // Add the new branch to the state
        state.branches.push(newBranch);
      }
    },
    addOptions: (state, action: PayloadAction<object[]>) => {
      const newOptions = action.payload;
      newOptions.forEach((newOption) => {
        const existingIndex = state.options.findIndex(
          (opt) => opt.value === newOption.value
        );
        if (existingIndex !== -1) {
          // Option with the same ves_id already exists, so overwrite it
          state.options[existingIndex] = newOption;
        } else {
          // Option with the same ves_id doesn't exist, so add it
          state.options.push(newOption);
        }
      });
    },
    deleteBranch: (state, action: PayloadAction<number>) => {
      state.branches = state.branches.filter(
        (branch) => branch.ves_id !== action.payload
      );
    },
  },
});

export const {
  setBranches,
  selectBranch,
  selectInvoiceAddress,
  updateBranch,
  createBranch,
  addOptions,
  deleteBranch,
} = branchSlice.actions;

export default branchSlice.reducer;
