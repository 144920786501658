import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BranchesService } from "../../services/branches/Branches";
import { addOptions, setBranches } from "../../state/branch/BranchSlice";
import { RootState } from "../../state/store";
import SelectBox from "../inputs/selectbox/SelectBox";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../buttons/LoadingSpinner";
import { stackAside } from "../../state/component/AsideSlice";
import FormButton from "../buttons/FormButton";


type Props = {
  handleSelect: (event: ChangeEvent<HTMLInputElement>) => void;
  defaultValue: string;
};

const BranchSelect: FC<Props> = ({ handleSelect, defaultValue }) => {
  const { t } = useTranslation();
  const [branchOptions, setBranchOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const branchService = new BranchesService();
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    Promise.all([fetchBranchOptions()]);
  }, []);

  const fetchBranchOptions = async () => {
    setLoading(true);
    try {
      const data = await branchService
        .getBranchOptions(companyId);
      setBranchOptions(data);
    } finally {
      setLoading(false);
    }   
  };

  const createBranch = async () => {
    dispatch(stackAside({
      component: "branchCreate",
      title: "Maak branch aan",
      data: { companyId },
      callback: () => {
        fetchBranchOptions();
      }
    }));
  }

  if (loading) {
    return <LoadingSpinner />;
  }

  return (

    (!branchOptions || branchOptions.length === 0) ? (
      <FormButton onClick={createBranch} text={t("Maak vestiging aan")} />
    ) : (
    <SelectBox
      onChange={handleSelect}
      defaultValue={branchOptions[0]?.value}
      options={branchOptions}
      loading={loading}
      search={false}
      disabled={branchOptions.length === 1}
    />
    )
  );
};

export default BranchSelect;
