import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { Button, Progress } from "antd";
import LoadingSpinner from "../../components/buttons/LoadingSpinner";
import TextHeader from "../../components/headers/TextHeader";
import Typography from "../../components/typography/Typography";
import Tag from "../../components/tags/Tag";
import { ProjectsService } from "../../services/project/Projects";
import Table from "../../components/tables/Table";
import TableItem from "../../components/tables/TableItem";
import DateFormatted from "../../components/typography/Date";
import Time from "../../components/typography/Time";
import { Link } from "gatsby";
import EmployeeThumb from "../../components/EmployeeThumb";
import { Edit, Tool } from "react-feather";
import { AsideOrNavigate } from "../../utils/navigation/AsideOrNavigate";
import { setRootAside } from "../../state/component/AsideSlice";
import { setCurrentProject } from "../../state/project/ProjectSlice";
import ProjectUsers from "./ProjectUsers";
import { useTranslation } from "react-i18next";
import { TicketService } from "../../services/tickets/Tickets";
import { addToast } from "../../state/toast/ToastSlice";

type Props = {
  projectId: Object;
};

const ProjectDetailContainer: FC<Props> = ({ projectId }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [ticketLoading, setTicketLoading] = useState(false);
  const [filter, setFilter] = useState("all");
  const dispatch = useDispatch();
  const project = useSelector(
    (state: RootState) => state.project.currentProject
  );
  const selectedCompany = useSelector(
    (state: RootState) => state.companies.selectedCompany
  );

  const ticketService = new TicketService();
  const projectService = new ProjectsService();

  useEffect(() => {
    if (selectedCompany.id) {
      setLoading(true);
      const fetchData = async () => {
        const project = await projectService.getProject(
          projectId,
          selectedCompany.id
        );
        dispatch(setCurrentProject(project));

        setLoading(false);
      };

      fetchData();
    }
  }, [projectId, selectedCompany.id]);

  const onClick = (type: string, task: object) => {
    const t = AsideOrNavigate(type);

    if (project.metadata && Object.keys(project.metadata).length > 0) {
      task = { ...task, metadata: project.metadata };
    }

    dispatch(
      setRootAside({
        component: t,
        data: { task: task },
        title: "Taak",
      })
    );
  };

  const handleFilter = (type: string) => {
    setFilter(type);
  };

  const filterTasks = (task) => {
    if (filter === "all") {
      return true;
    } else if (filter === "mine") {
      return task.medewerker !== null;
    } else if (filter === "open") {
      return task.status?.toLowerCase() !== "gesloten";
    }
    return true;
  };

  const fetchTickets = async (projectId: number) => {
    const tickets = await ticketService.getTickets(
      selectedCompany.id,
      "PROJECT",
      projectId
    );
    return tickets;
  };

  const viewDetail = async (projectId: number) => {
    setTicketLoading(true);
    let ticket = await fetchTickets(projectId);
    
    if (ticket.zendesk.length === 0) {
      dispatch(
        addToast({
          style: "error",
          description: t("Er is geen ticket gevonden voor dit project")
        })
      )

      setTicketLoading(false);
      return;
    }

    dispatch(
      setRootAside({
        component: "ticket",
        data: { ticket: ticket.zendesk[0] },
        title: "Ticket",
      })
    );
    setTicketLoading(false);
  };

  const tableHeaders: object = [
    "Omschrijving",
    "Verantwoordelijke",
    "Laatste update",
    "Status",
    "Acties",
  ];

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="overflow-y-hidden">
          <div className="mb-5">
            <Link to="/project/overview">Projecten</Link>
            <span> &gt; </span>
            <Typography tag="span" type="semibold">
              Project
            </Typography>
          </div>
          <TextHeader
            title={project.proj_onderwerp}
            subtitle={project.proj_number}
          />
          <div className="grid grid-cols-6">
            <div className="col-span-6 md:col-span-4 lg:col-span-3 2xl:col-span-2">
              <div className="mt-5">
                <Progress percent={project.proj_progress} />
              </div>
              <div className="grid grid-cols-3 my-2 text-center">
                <div>
                  <Typography tag={"p"} type={"semibold"}>
                    Projectleider
                  </Typography>
                  <Typography tag={"p"} type={"default"}>
                    {project.project_leader?.name ?? "Niet toegewezen"}
                  </Typography>
                </div>
                <div>
                  <Typography tag={"p"} type={"semibold"}>
                    Status
                  </Typography>
                  <Typography tag={"p"} type={"default"}>
                    <Tag
                      style={project.proj_status}
                      label={String(project.proj_status)}
                    />
                  </Typography>
                </div>
                <div>
                  <Typography tag={"p"} type={"semibold"}>
                    Communicatie
                  </Typography>
                  <Button
                    onClick={() => viewDetail(project.proj_id)}
                    loading={ticketLoading}
                  >
                    {t("Open ticket")}
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-span-6 lg:col-span-3 2xl:col-span-4 flex items-end justify-end mb-2">
              {project.employees ? (
                <ProjectUsers
                  users={[
                    ...(Array.isArray(project?.employees)
                      ? project.employees
                      : []),
                    ...(Array.isArray(project?.users)
                      ? project.users.map((user) => ({
                          ...user,
                          name: `${user.geb_voornaam || ""} ${
                            user.geb_naam || ""
                          }`.trim(),
                        }))
                      : []),
                  ]}
                />
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="border-t dark:border-gray-700">
            <div className="mt-3 flex gap-1">
              <Button onClick={() => handleFilter("all")}>
                {t("Alle regels")}
              </Button>
              {/* <Button onClick={() => handleFilter("mine")}>Mijn regels</Button> */}
              <Button onClick={() => handleFilter("open")}>
                {t("Open regels")}
              </Button>
            </div>
            {project?.phases?.map((sprint) => (
              <div className="mt-5" key={sprint.phase_id}>
                <div className="w-full md:w-2/3 lg:w-1/2 xl:w-1/3 mt-5 mb-3">
                  <Typography tag={"p"} type={"subtitle"}>
                    {sprint.phase_subject}
                  </Typography>
                  {/* <Progress percent={sprint.projFace_done_percentage} /> */}
                </div>

                {sprint?.phase_rules?.filter(filterTasks).length > 0 ? (
                  <div className="border overflow-y-hidden dark:border-gray-700 rounded-lg">
                    <Table header={tableHeaders}>
                      {sprint?.phase_rules?.filter(filterTasks).map((task) => (
                        <tr key={task.rule_id}>
                          <TableItem>{task.description}</TableItem>
                          <TableItem>
                            <div className="flex items-center gap-3">
                              {task?.medwerker ? (
                                <>
                                  <EmployeeThumb
                                    name={task.medewerker?.name}
                                    image={task.medewerker?.photo}
                                  />
                                  {task.medewerker?.name}
                                </>
                              ) : (
                                <Typography tag={"p"} type={"default"}>
                                  {task.extra}
                                </Typography>
                              )}
                            </div>
                          </TableItem>
                          <TableItem>
                            <Time time={task.updated_at} />
                            &nbsp;
                            <DateFormatted date={task.updated_at} />
                          </TableItem>
                          <TableItem>
                            <Tag
                              style={task?.status ?? "Nieuw"}
                              label={task?.status ?? "Nieuw"}
                            />
                          </TableItem>
                          <TableItem>
                            {task?.status?.toLowerCase() !== "gesloten" &&
                            task?.extra === selectedCompany?.companyName ? (
                              <button onClick={() => onClick(task.type, task)}>
                                <Tool size={18} />
                              </button>
                            ) : (
                              ""
                            )}
                          </TableItem>
                        </tr>
                      ))}
                    </Table>
                  </div>
                ) : (
                  <div>Geen taken voor deze sprint</div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default ProjectDetailContainer;
