import React, { FC, useState } from "react";
import Form from "../Form";
import SelectTarif from "../../../organisms/simcards/SelectTarif";
import Typography from "../../typography/Typography";
import dayjs from "dayjs";
import DateRange from "../../inputs/dates/DateRange";
import RatePlanInfo from "../../typography/sim/RatePlanInfo";
import Cta from "../../buttons/Cta";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import { TicketService } from "../../../services/tickets/Tickets";
import { TarifService } from "../../../services/simcards/Tarifs";
import { updateAside } from "../../../state/component/AsideSlice";
import { setSimcard } from "../../../state/simdata/SimcardsSlice";
import { SimcardService } from "../../../services/simcards/Simcards";
import { Trash2 } from "react-feather";
import { addToast } from "../../../state/toast/ToastSlice";
import TarifRoaming from "../../../organisms/simcards/TarifRoaming";
import LoadingSpinner from "../../buttons/LoadingSpinner";

type props = {
  currentTarifId: number | undefined;
  currentValue: string | undefined;
  data: any;
};

const AddOption: FC<props> = ({ currentTarifId, currentValue, data: { sim } }) => {
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [date, setDate] = useState([dayjs()]);
  const [tarif, setTarif] = useState();
  const [roaming, setRoaming] = useState(undefined);

  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const ticketService = new TicketService();
  const simService = new SimcardService();
  const tarifService = new TarifService();
  const dispatch = useDispatch();

  const handleChange = async (event: number) => {
    setRoaming(undefined);
    if (companyId) {
      setDataLoading(true);
      setTarif(event);

      const tarifDetail = await tarifService.getTarif(event, companyId);
      setRoaming(tarifDetail);
      setDataLoading(false);
    }
  };

  const handleDate = (date, dateString) => {
    setDate(date);
  };

  const removeOption = async () => {
    setLoading(true);
    await tarifService.removeOption(sim.sim_options[0].apt_id, companyId);

    const prop = {
      sim_options: [],
    };

    dispatch(updateAside({ props: prop, object: "sim" }));
    dispatch(setSimcard({ props: prop, key: sim.key }));

    const description = `Opzeggen optie: ${sim.sim_options[0]?.trf_naam} voor simkaart ${sim.sim_unique_nr}`;

    setLoading(false);

    dispatch(
      addToast({
        description: [
          "Opzeggen optie",
          {
            trf_naam: sim.sim_options[0]?.trf_naam,
            sim_unique_nr: sim.sim_unique_nr,
          },
        ],
        position: "bottomRight",
        style: "success",
      })
    );

    ticketService.createTicketZendesk(companyId, {
      subject: `Opzeggen optie ${sim.sim_options[0]?.trf_naam}`,
      description: description,
      department: "Telecom",
      linkedResource: {
        id: sim.sim_unique_nr,
        type: "TELECOM",
      },
      // meta: sim,
      // id: sim.sim_unique_nr,
    });

    dispatch(
      addToast({
        description: "Er is een ticket aangemaakt.",
        position: "bottomRight",
        style: "success",
      })
    );
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    if (!date.length) {
      setLoading(false);
      return false;
    }

    if (!tarif) {
      setLoading(false);
      return false;
    }

    const body = {
      sim_id: sim.sim_id,
      trf_id: tarif,
      startDate: date[0].format("YYYY-MM-DD"),
      endDate: date.length > 1 ? date[1].format("YYYY-MM-DD") : null,
    };
    const option = await tarifService.addOption(body, companyId);

    console.log(option);

    const dates = {
      startDate: date[0].format("DD/MM/YYYY"),
      endDate: date.length > 1 ? date[1].format("DD/MM/YYYY") : "∞",
    };

    const prop = {
      sim_status: "Optie toevoegen",
      sim_options: [option],
    };

    dispatch(updateAside({ props: prop, object: "sim" }));
    dispatch(setSimcard({ props: prop, key: sim.key }));

    await simService.updateSimcard(
      sim.sim_id,
      {
        sim_status: prop.sim_status,
      },
      companyId
    );

    ticketService.createTicketZendesk(companyId, {
      subject: `Toevoegen optie ${option?.trf_naam}`,
      description: `Toevoegen optie: ${option?.trf_naam} voor simkaart ${sim.sim_unique_nr} vanaf ${dates.startDate} tot ${dates.endDate}`,
      department: "Telecom",
      linkedResource: {
        id: sim.sim_unique_nr,
        type: "TELECOM",
      },
      // meta: sim,
      // id: sim.sim_unique_nr,
    });

    setLoading(false);
  };

  return (
    <div>
      {!sim.sim_options || sim.sim_options.length === 0 ? (
        <Form
          buttonLabel="Indienen"
          handleSubmit={handleSubmit}
          gridSize="grid-cols-2"
          loading={loading}
        >
          <Typography tag="label" type="label">
            Selecteer een optie
          </Typography>
          <SelectTarif
            onSelect={handleChange}
            defaultValue={currentValue}
            type="option"
          />
          {dataLoading && (
            <div className="mt-2">
              <LoadingSpinner />
            </div>
          )}
          {tarif && <TarifRoaming roaming={roaming} />}
          <div className="block mt-2 mb-2">
            <Typography tag="label" type="label">
              Start en einddatum
            </Typography>
            <div className="block">
              <DateRange onChange={handleDate} value={date} />
            </div>
          </div>
        </Form>
      ) : (
        <>
          <p>{sim.sim_options[0]?.trf_naam}</p>
          <RatePlanInfo ratePlan={sim.sim_options[0]} />
          <div className="mt-4"></div>
          <Cta
            text="Opzeggen"
            loading={loading}
            style="danger"
            onClick={removeOption}
            icon={<Trash2 size={14} />}
          />
        </>
      )}
    </div>
  );
};

export default AddOption;
