import React, { FC, useEffect, useReducer, useState } from "react";
import TextHeader from "../../components/headers/TextHeader";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../state/store";
import LoadingSpinner from "../../components/buttons/LoadingSpinner";
import Tabs from "../../components/tabs/Tabs";
import ProductInfo from "./ProductInfo";
import { AssetsService } from "../../services/assets/Assets";
import { UsersService } from "../../services/users/Users";
import {
  updateHardware,
} from "../../state/hardware/HardwareSlice";
import { addToast } from "../../state/toast/ToastSlice";
import HardwareTickets from "./HardwareTickets";
import Tag from "../../components/tags/Tag";
import { Trash2 } from "react-feather";
import UserSelect from "../../components/users/UserSelect";
import { useTranslation } from "react-i18next";

type Asset = {
  asset: any;
  data: {
    asset: any;
  }
};

type tag = { 
  id: number;
  tag: string;
}

const AssetDetailContainer: FC<Asset> = ({ asset, data }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);

  const [formLoading, setFormLoading] = useState(true);
  const [tagLoading, setTagLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const [options, setOptions] = useState([]);

  const [tags, setTags] = useState<tag[]>();

  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );

  const [assetData, setAssetData] = useState(data.asset);

  const service = new UsersService();
  const assetService = new AssetsService();
  const dispatch = useDispatch();

  const [force, forceUpdate] = useReducer((x) => x + 1, 0);

  const tabs = [
    {
      nav: "Productinfo",
      component: [
        <ProductInfo
          asset={data.asset}
          category={data.asset.ordPro_categorie}
        />,
      ],
    },
    {
      nav: "Tickets",
      component: [<HardwareTickets assetId={data.asset.ordSer_id} />],
    },
  ];

  useEffect(() => {
    setTags(data.asset.tags);
  }, []);

  useEffect(() => {
    setLoading(true);
    setFormLoading(true);

    const fetchData = async () => {
      await fetchOptions();

      setFormLoading(false);
      setLoading(false);
    };

    fetchData();
  }, [data.asset]);

  const fetchOptions = async () => {
    const fetchedOptions = await service.getUserNames(companyId);
    setOptions(fetchedOptions);
    setAssetData(data.asset);
  };

  const handleSelect = async (value: string, label: string) => {
    const asset = await assetService.updateAssetUser(
      companyId,
      data.asset.ordSer_id,
      value,
      label.label
    );

    console.log(asset);

    dispatch(
      updateHardware({
        id: data.asset.ordSer_id,
        updatedData: {
          ...data.asset,
          ordSer_user_id: value,
          ordSer_username: label.label,
        },
      })
    );

    dispatch(
      addToast({
        description: "De gebruiker is gewijzigd.",
        position: "bottomRight",
        style: "success",
      })
    );
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyPress = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      setTagLoading(true);
      try {
        const tag = await assetService.createTag(
          companyId,
          // order_product_id: data.asset.ordSer_ordPro_id,
          data.asset.ordSer_id,
          inputValue.trim()
        );

        const newTags = [
          ...tags,
          {
            id: tag.tagId,
            tag: inputValue.trim(),
          },
        ]

        setTags(newTags);
        dispatch(
          updateHardware({
            id: data.asset.ordSer_id,
            updatedData: { ...data.asset, tags: newTags },
          })
        );
        setInputValue("");
      } catch (error) {
        console.error("Error creating tag:", error);
      } finally {
        setTagLoading(false);
      }
    }
  };

  const removeTag = async (tagId: number) => {
    try {
      await assetService.deleteTag(companyId, data.asset.ordSer_id, tagId);
      const updatedTags = tags.filter((tag) => tag.id !== tagId);
      setTags(updatedTags);
      dispatch(updateHardware({ id: data.asset.ordSer_id, updatedData: { ...data.asset, tags: updatedTags } }));
    } catch (error) {
      console.error("Error deleting tag:", error);
    }
  };

  const handleRemoveUser = async () => {
    setLoading(true);
    try {
      const asset = await assetService.removeUser(
        companyId,
        data.asset.ordSer_id
      );
      
      const updatedHardware = { ...assetData, ordSer_user_id: null, ordSer_username: "" };

      setAssetData(updatedHardware);
      dispatch(
        updateHardware({ id: data.asset.ordSer_id, updatedData: updatedHardware })
      );

      // setAssetData(asset);
      forceUpdate();

      dispatch(
        addToast({
          description: "Gebruiker verwijderd van het apparaat.",
          position: "bottomRight",
          style: "success",
        })
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error removing user from asset:", error);
    }
  };

  return (
    <div>
      <div>
        <TextHeader
          title={data.asset.ordPro_naam}
          subtitle={data.asset.ordSer_serial}
        />
      </div>
      {formLoading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <div className="grid grid-cols-2 mt-5 gap-3">
            <UserSelect
              key={force}
              handleSelect={handleSelect}
              defaultValue={assetData.ordSer_user_id ?? "Selecteer gebruiker"}
              loading={loading}
            />
            <div>
              <button
                className="bg-red-500 text-white p-2 rounded-lg shadow-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
                onClick={handleRemoveUser}
              >
                <Trash2 size={18} />
              </button>
            </div>
            <div className="col-span-2">
              <div className="relative">
                {tagLoading && (
                  <div className="absolute right-1 flex items-center inset-y-0">
                    <LoadingSpinner size={18} />
                  </div>
                )}
                <input
                  className={`border border-gray-300 dark:border-slate-600 dark:text-gray-300 text-sm rounded-lg block w-full px-2.5 py-1.5 bg-transparent dark:bg-gray-900 focus:outline-blue-700 ${
                    tagLoading ? "pr-10" : ""
                  }`}
                  type="text"
                  placeholder={t("Voeg tags toe (druk op Enter)")}
                  value={inputValue}
                  onChange={handleInputChange}
                  onKeyDown={handleInputKeyPress}
                  disabled={tagLoading}
                />
              </div>
              {(tags?.length !== 0) && (
                <div className="mt-2 flex gap-1">
                  {tags?.map((tag, index) => (
                    <div
                      className="hover:cursor-pointer"
                      onClick={() => removeTag(tag.id)}
                    >
                      <Tag key={index} style={"hover"} label={tag.tag} />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <Tabs tabs={tabs} />
        </div>
      )}
    </div>
  );
};

export default AssetDetailContainer;
