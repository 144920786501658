import React, { FC } from "react";
import { Eye } from "react-feather";
import { useDispatch } from "react-redux";
import { setRootAside } from "../../state/component/AsideSlice";
import Table from "../tables/Table";
import TableRow from "../tables/TableRow";
import TableItem from "../tables/TableItem";
import DateFormatted from "../typography/Date";
import Tag from "../tags/Tag";
import Typography from "../typography/Typography";
import { TruncateString } from "../../utils/text/TruncateString";

type Props = {
  tickets: object[];
};

const TicketTable: FC<Props> = ({ tickets }) => {
  const dispatch = useDispatch();

  const viewTicket = async (ticket: any) => {
    dispatch(
      setRootAside({
        component: "ticket",
        data: { ticket: ticket },
        title: `Ticket #${ticket.id}`,
      })
    );
  };

  const tableHeaders: object = [
    "Status",
    "Ticket",
    "Onderwerp",
    "Datum",
    // "Afdeling",
    "",
  ];

  return (
    <div className="h-full">
      {tickets.length > 0 ? (
        <div className="border-b dark:border-gray-700">
          <Table header={tableHeaders}>
            {tickets.map((ticket: any) => (
              <TableRow
                onClick={() => viewTicket(ticket)}
                key={ticket.id}
                className="cursor-pointer"
              >
                <TableItem>
                  <Tag style={ticket.status} label={ticket.status} />
                </TableItem>
                <TableItem>#{ticket.id}</TableItem>
                <TableItem>
                  <TruncateString text={ticket.subject} limit={30} />
                </TableItem>
                <TableItem>
                  <DateFormatted date={ticket.created_at} />
                </TableItem>
                {/* <TableItem>
                  <Tag style={"cyod"} label={ticket.tic_afdeling} />
                </TableItem> */}
                <TableItem>
                  <button onClick={() => viewTicket(ticket)}>
                    <Eye size={18} />
                  </button>
                </TableItem>
              </TableRow>
            ))}
          </Table>
        </div>
      ) : (
        <div className="h-full flex items-center">
          <Typography type={"default"} tag={"h1"}>
            Geen tickets
          </Typography>
        </div>
      )}
    </div>
  );
};

export default TicketTable;
